import invert from "lodash/invert";
const isInternal = process.env.BUILD_TARGET === "client" && !!window?.__GLOBAL_CONTEXT__?.isInternal;
const names = {};
export const get = (experiment, type, value) => ((names[experiment] || {})[type] || {})[value || ""];
export const set = (config) => {
    if (!isInternal)
        return;
    names[config.name] = {
        stage: invert(config.stages),
        customGoal: invert(config.goals)
    };
};
