import React from "react";
import { useHistory } from "react-router-dom";

import { Breadcrumbs, Button, Stack } from "@bookingcom/bui-react";
import { t } from "@bookingcom/lingojs-core";
import { useI18n } from "@bookingcom/lingojs-react";

import { UIFlightSegment, UIOrder } from "@flights/types";
import useCreateUrl from "hooks/useCreateUrl";
import useGlobalContext from "hooks/useGlobalContext";
import useEventTracking from "hooks/useEventTracking";
import { FilteredLegWithOpenedCheckIn } from "utils/get-filtered-legs-with-opened-checkIn";

import ConfirmationController from "../ConfirmationController";
import { CheckinHeader } from "./CheckinHeader";
import { CheckinHowTo } from "./CheckinHowTo";
import { CheckinSegmentHeader } from "./CheckinSegmentHeader";
import Wrapper from "../../atoms/Wrapper";
import { CheckInInfoCard } from "./CheckinInfoCard";
import { CheckinTimeInfoBanner } from "./CheckinTimeInfoBanner";

interface WebCheckinInnerProps {
  order: UIOrder;
  segmentIndex: number;
  segment: UIFlightSegment;
  filteredLegsWithOpenedCheckIn: FilteredLegWithOpenedCheckIn[];
}

export const WebCheckinInner: React.FC<WebCheckinInnerProps> = ({
  order,
  segmentIndex,
  segment,
  filteredLegsWithOpenedCheckIn
}) => {
  const i18n = useI18n();
  const history = useHistory();
  const { requestId } = useGlobalContext();
  const trackV2 = useEventTracking("pb_webcheckin", requestId);
  const { createUrl } = useCreateUrl();

  const handleSkipAllClick = () => {
    trackV2("click_skip_checkin", {
      order_id: order.orderId,
      segment: segmentIndex,
      legs: segment && [...segment.legs.keys()]
    });
    history.push(createUrl(`/booking/order-details/${order.orderToken}`));
  };

  const showSkipAllButton = React.useMemo(() => {
    const openedCheckInsCount = filteredLegsWithOpenedCheckIn.filter(({ checkinInfoForLeg }) => {
      return checkinInfoForLeg.isOnlineCheckinOpen;
    }).length;

    return openedCheckInsCount > 1;
  }, [filteredLegsWithOpenedCheckIn]);

  // TODO: https://jira.booking.com/jira/browse/FLPB-1531
  const isCheckinM5ExperimentEnabled = false;

  return (
    <ConfirmationController>
      {() => (
        <>
          <Wrapper noBorder={isCheckinM5ExperimentEnabled}>
            <Stack gap={4}>
              <Breadcrumbs
                back
                items={[
                  {
                    text: i18n.trans(t("flights_pb_checkin_navigation")),
                    href: createUrl(`/booking/order-details/${order?.orderToken}`)
                  }
                ]}
              />
              <CheckinHeader segment={segment} />
              {!isCheckinM5ExperimentEnabled && <div />}
            </Stack>
          </Wrapper>
          {isCheckinM5ExperimentEnabled && (
            <Wrapper style={{ paddingTop: 0 }}>
              <CheckinTimeInfoBanner
                isLessThan24Hours={filteredLegsWithOpenedCheckIn.some(
                  ({ checkinInfoForLeg }) => checkinInfoForLeg.isOnlineCheckinOpen
                )}
              />
            </Wrapper>
          )}
          <Wrapper>
            <CheckinHowTo order={order} />
          </Wrapper>
          <Wrapper>
            <Stack gap={4}>
              <CheckinSegmentHeader segment={segment} showSubHeader={filteredLegsWithOpenedCheckIn.length === 1} />
              {filteredLegsWithOpenedCheckIn.map(({ leg, checkinInfoForLeg }) => {
                const {
                  legIdentifier: { segmentIndex, legIndex },
                  isOnlineCheckinOpen
                } = checkinInfoForLeg;
                return (
                  <CheckInInfoCard
                    key={legIndex}
                    leg={leg}
                    segment={segment}
                    segmentIndex={segmentIndex}
                    legIndex={legIndex}
                    openCheckin={isOnlineCheckinOpen}
                    checkInInfo={checkinInfoForLeg}
                    showSkipAllButton={showSkipAllButton}
                    showHeader={filteredLegsWithOpenedCheckIn.length > 1}
                    showVuelingAlert
                  />
                );
              })}
              {showSkipAllButton && (
                <Stack direction="row">
                  <Button
                    wide
                    size="large"
                    attributes={{ "aria-label": i18n.trans(t("a11y_flights_pb_checkin_vi_skip_cta")) }}
                    variant="tertiary"
                    onClick={handleSkipAllClick}
                  >
                    {i18n.trans(t("flights_pb_checkin_vi_skip_cta"))}
                  </Button>
                </Stack>
              )}
            </Stack>
          </Wrapper>
        </>
      )}
    </ConfirmationController>
  );
};
