import React from "react";
import { Stack, Text } from "@bookingcom/bui-react";
import { t, useI18n } from "@bookingcom/lingojs-react";
import { customGoal } from "@flights/et-universal";
import Frame from "components/elements/Frame";
import { WINTER_DEALS_IMAGE_MDOT } from "../../../constants";

const initialStyle = {
  backgroundImage: `linear-gradient(90deg, rgb(26, 26, 26) 10%, transparent 50%), url(${WINTER_DEALS_IMAGE_MDOT})`,
  backgroundSize: "cover",
  backgroundPosition: "center",
  backgroundRepeat: "no-repeat",
  borderRadius: "var(--bui_border_radius_200)",
  minHeight: "300px"
};

const WinterDealsBanner = () => {
  const i18n = useI18n();

  const handleClick = () => {
    customGoal("flights_web_cat_winter_deals_promo", 1);
  };

  return (
    <Frame attributes={{ style: { width: "100%" }, onClick: handleClick }} mb={2} mt={4} p={4}>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        attributes={{
          style: initialStyle
        }}
      >
        <Stack.Item grow>
          <Text
            color="white"
            variant="headline_3"
            attributes={{ style: { maxWidth: "50%", padding: "var(--bui_spacing_4x)" } }}
          >
            {i18n.trans(t("brand_indexbanners_flights_winterdeal_jan_2025_herobanner_desktop_title"))}
          </Text>
        </Stack.Item>
      </Stack>
    </Frame>
  );
};

export default WinterDealsBanner;
