/**
 * ============================================================================
 * ======== Dont use this to track your experiments. Use etV2 instead. ========
 * ============================================================================
 */
import {
  customGoal as v2CustomGoal,
  goalWithValue as v2GoalWithValue,
  stage as v2Stage,
  goal as v2Goal,
  trackWithDefaultStage as v2TrackWithDefaultStage,
  track as v2Track // eslint-disable-line import/no-deprecated
} from "@flights/et-universal";
import { setCookie } from "./cookies";
import { ET_MICROCONVERSION_EXP_NAME, ET_MICROCONVERSION_COOKIE_NAME } from "../constants";

/**
 * ### Experimental!
 * do not use in your experiments. this is an in-progress experimental way of mesuring microconversion without blackoout.
 *
 * More info: [et-microconversion.md](./et-microconversion.md)
 *
 * ### Example:
 * ```javascript
 * // only if you know what you are doing
 * import etMicroconversion from "utils/et-microconversion";
 * etMicroconversion.trackWithDefaultStage("tag_name", 1);
 * ```
 */
export function trackWithDefaultStage(hashedTagname: string, defaultId: number) {
  const result = v2TrackWithDefaultStage(hashedTagname, defaultId);
  if (hashedTagname !== ET_MICROCONVERSION_EXP_NAME && Boolean(result)) {
    v2TrackWithDefaultStage(ET_MICROCONVERSION_EXP_NAME, 1);
  }
  return result;
}

/**
 * ### Experimental!
 * do not use in your experiments. this is an in-progress experimental way of mesuring microconversion without blackoout.
 *
 * More info: [et-microconversion.md](./et-microconversion.md)
 *
 * ### Example:
 * ```javascript
 * // only if you know what you are doing
 * import etMicroconversion from "utils/et-microconversion";
 * etMicroconversion.stage("tag_name", 1, 3);
 * ```
 */
export function stage(hashedTagname: string, id: number, isMainId: boolean) {
  if (isMainId) {
    v2Stage(ET_MICROCONVERSION_EXP_NAME, 2);
    // eslint-disable-next-line import/no-deprecated
    if (Boolean(v2Track(ET_MICROCONVERSION_EXP_NAME))) {
      setCookie(ET_MICROCONVERSION_COOKIE_NAME, "1", { maxAge: 21 * 86400_000 }); // 21 days
    }
  }
  return v2Stage(hashedTagname, id);
}

/**
 * ### Experimental!
 * do not use in your experiments. this is an in-progress experimental way of mesuring microconversion without blackoout.
 *
 * More info: [et-microconversion.md](./et-microconversion.md)
 *
 * ### Example:
 * ```javascript
 * // only if you know what you are doing
 * import etMicroconversion from "utils/et-microconversion";
 * etMicroconversion.goal("goal_name", 1);
 * ```
 */
export function goal(goalName: string) {
  return v2Goal(goalName);
}

/**
 * ### Experimental!
 * do not use in your experiments. this is an in-progress experimental way of mesuring microconversion without blackoout.
 *
 * More info: [et-microconversion.md](./et-microconversion.md)
 *
 * ### Example:
 * ```javascript
 * // only if you know what you are doing
 * import etMicroconversion from "utils/et-microconversion";
 * etMicroconversion.goalWithValue("goal_name", 1);
 * ```
 */
export function goalWithValue(goalName: string, value: number) {
  return v2GoalWithValue(goalName, value);
}

/**
 * ### Experimental!
 * do not use in your experiments. this is an in-progress experimental way of mesuring microconversion without blackoout.
 *
 * More info: [et-microconversion.md](./et-microconversion.md)
 *
 * ### Example:
 * ```javascript
 * // only if you know what you are doing
 * import etMicroconversion from "utils/et-microconversion";
 * etMicroconversion.customGoal("tag_name", 1);
 * ```
 */
export function customGoal(hashedTagname: string, goal: number) {
  return v2CustomGoal(hashedTagname, goal);
}

export default {
  customGoal,
  goal,
  goalWithValue,
  stage,
  trackWithDefaultStage
};
