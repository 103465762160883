import useEventTracking from "hooks/useEventTracking";
import useGlobalContext from "hooks/useGlobalContext";
import { useCallback, useEffect } from "react";
import { useSelector } from "react-redux";
import { getEsimFlight, getEsimIndex, getEsimOrder, getEsimSearch } from "store/esim/selectors";
import { getOrder } from "store/order/selectors";
import { getGeniusLevel } from "store/user/selectors";
import { UIEsimContext } from "@flights/types";

export const useTrackEsimShowBanner = (hasButton: boolean, context: UIEsimContext) => {
  const { requestId } = useGlobalContext();
  const trackV2 = useEventTracking("esim", requestId);
  const order = useSelector(getOrder);
  const geniusLevel = useSelector(getGeniusLevel);
  const getEsim = getTrackingPropsFromContext(context);
  const esim = useSelector(getEsim);

  useEffect(() => {
    trackV2("show_banner_esim", {
      orderId: order?.orderId || null,
      order_status: order?.orderStatus || null,
      genius_level: geniusLevel || null,
      destination: esim?.validCountry || null,
      action: hasButton ? "clickable" : "non-clickable",
      valid_until: esim?.validTill || null,
      placement: "banner",
      search_requestid: requestId || null
    });
  }, [
    esim?.validCountry,
    esim?.validTill,
    geniusLevel,
    hasButton,
    order?.orderId,
    order?.orderStatus,
    requestId,
    trackV2
  ]);
};

export const useEsimClickBanner = (esimContext: UIEsimContext) => {
  const { requestId } = useGlobalContext();
  const trackV2 = useEventTracking("esim", requestId);
  const order = useSelector(getOrder);
  const geniusLevel = useSelector(getGeniusLevel);
  const getEsim = getTrackingPropsFromContext(esimContext);
  const esim = useSelector(getEsim);

  return useCallback(
    (hasButton: boolean) => {
      trackV2("click_banner_esim", {
        orderId: order?.orderId || null,
        order_status: order?.orderStatus || null,
        genius_level: geniusLevel || null,
        destination: esim?.validCountry || null,
        action: hasButton ? "clickable" : "non-clickable",
        valid_until: esim?.validTill || null
      });
    },
    [trackV2, order, geniusLevel, esim]
  );
};

export const useEsimClickEmail = () => {
  const { requestId } = useGlobalContext();
  const trackV2 = useEventTracking("esim", requestId);
  const order = useSelector(getOrder);
  const geniusLevel = useSelector(getGeniusLevel);
  const getEsim = getTrackingPropsFromContext("order");
  const esim = useSelector(getEsim);

  return useCallback(
    (hasButton: boolean) => {
      trackV2("click_email_esim", {
        orderId: order?.orderId || null,
        order_status: order?.orderStatus || null,
        genius_level: geniusLevel || null,
        destination: esim?.validCountry || null,
        action: hasButton ? "clickable" : "non-clickable",
        valid_until: esim?.validTill || null
      });
    },
    [trackV2, order, geniusLevel, esim]
  );
};

function getTrackingPropsFromContext(context: UIEsimContext) {
  switch (context) {
    case "index":
      return getEsimIndex;
    case "search":
      return getEsimSearch;
    case "flight":
      return getEsimFlight;
    case "order":
      return getEsimOrder;

    default:
      return getEsimSearch;
  }
}
