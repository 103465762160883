/* start - flights_web_persist_checkout_selections */
import { createListenerMiddleware } from "@reduxjs/toolkit";
import { ActionTypes as ExtraProductsActionTypes, actions } from "./extraProducts/actions";
import { ActionTypes as FlightDetailsActionTypes, actions as flightDetailsActions } from "./flightDetails/actions";
import { ActionTypes as OrderActionsTypes, actions as orderActions } from "./order/actions";
import flights_web_persist_checkout_selections from "utils/experiments/funnel/flights_web_persist_checkout_selections";
import { RootState } from "store";
import extraProductsPersistedStore from "utils/extraProductsPersistedStore";
import { flightsSessionStore } from "@flights/web-api-utils-universal";
import { ALTERNATIVE_FARES_FLIGHT_OFFER_OBJECT_KEY, CURRENT_ORDER_ID_KEY } from "../constants";

export const listenerMiddleware = createListenerMiddleware();

// When we add/remove products, we update the persistent store.
const addExtraProductEffect = (
  { payload: { productType } }: ReturnType<typeof actions.addProduct | typeof actions.setProducts>,
  listenerApi: unknown
) => {
  if (!!flights_web_persist_checkout_selections.trackWithDefaultStage()) {
    const rootState = (listenerApi as { getState: () => RootState }).getState();
    const offerToken = rootState.flightDetails.flight?.token || "";
    const selectedExtraProducts = rootState.extraProducts.selected;

    if (!offerToken) {
      throw new Error("No offerToken found while updating persisted store");
    }

    extraProductsPersistedStore.add({
      offerToken,
      key: productType,
      value: selectedExtraProducts[productType]
    });
  }
};

const removeExtraProductEffect = (
  { payload: { productType } }: ReturnType<typeof actions.removeProduct | typeof actions.removeAllProducts>,
  listenerApi: unknown
) => {
  if (!!flights_web_persist_checkout_selections.trackWithDefaultStage()) {
    const rootState = (listenerApi as { getState: () => RootState }).getState();
    const offerToken = rootState.flightDetails.flight?.token || "";

    if (!offerToken) {
      throw new Error("No offerToken found while updating persisted store");
    }

    extraProductsPersistedStore.remove({
      offerToken,
      key: productType
    });
  }
};

listenerMiddleware.startListening({
  type: ExtraProductsActionTypes.addProduct,
  effect: (action: ReturnType<typeof actions.addProduct>, listenerApi) => addExtraProductEffect(action, listenerApi)
});

listenerMiddleware.startListening({
  type: ExtraProductsActionTypes.removeProduct,
  effect: (action: ReturnType<typeof actions.removeProduct>, listenerApi) =>
    removeExtraProductEffect(action, listenerApi)
});

listenerMiddleware.startListening({
  type: ExtraProductsActionTypes.setProducts,
  effect: (action: ReturnType<typeof actions.setProducts>, listenerApi) => addExtraProductEffect(action, listenerApi)
});

listenerMiddleware.startListening({
  type: ExtraProductsActionTypes.removeAllProducts,
  effect: (action: ReturnType<typeof actions.removeAllProducts>, listenerApi) =>
    removeExtraProductEffect(action, listenerApi)
});
/* end - flights_web_persist_checkout_selections */

// Write flight to session storage to persist flight data if user reloads during alternative fares flow
listenerMiddleware.startListening({
  type: FlightDetailsActionTypes.fetchSuccess,
  effect: (action: ReturnType<typeof flightDetailsActions.fetchSuccess>) => {
    const flight = action.payload.flight;

    flightsSessionStore.set(ALTERNATIVE_FARES_FLIGHT_OFFER_OBJECT_KEY, flight);
  }
});

// Write the current order ID to session storage to persist it through reloads during alternative fares flow
listenerMiddleware.startListening({
  type: OrderActionsTypes.fetchSuccess,
  effect: (action: ReturnType<typeof orderActions.fetchSuccess>) => {
    const orderId = action.payload.order.orderId;

    flightsSessionStore.set(CURRENT_ORDER_ID_KEY, orderId);
  }
});
