import React from "react";

type Props = {
  height: string;
  color: string;
};

export const HorizontalDivider = ({ height, color }: Props) => {
  return (
    <div
      style={{
        width: "100%",
        height: height,
        backgroundColor: color
      }}
    />
  );
};

export default HorizontalDivider;
