import { createTrackingUtils } from "..";

const utils = createTrackingUtils({
  name: "flights_web_hide_genius_banner",
  stages: {
    sr_fd_logged_in: 1, // landing on search results / flight details for logged in users
    main: 2, // search results where banner is shown / flight details are fully fetched
    direct: 3,
    meta: 4,
    desktop: 5,
    mobile: 6
  },
  defaultStage: 1,
  goals: {},
  goalsWithValue: []
});

export default utils;
