import { createTrackingUtils } from "..";

const utils = createTrackingUtils({
  name: "flights_apex_web_bf_on_sr_loading_message",
  stages: {
    main: 1,
    mdot: 2,
    ddot: 3,
    false_positive: 4,
    not_false_positive: 5
  },
  defaultStage: 1,
  goals: {
    click_on_view_details_for_main_flight: 1,
    click_on_any_fare_card: 2,
    click_on_upsell_fare_card: 3,
    close_expanded_branded_fares: 4,
    waited_until_loading_is_done: 5
  }
});

export default utils;
