/* eslint-disable no-restricted-syntax -- this line was auto generated, hence fix the issue timely */
import React from "react";
import { useParams } from "react-router-dom";

import { LiveChatLoader } from "../components/elements/LiveChatWidget/LiveChatWidget";
import ConfirmationInnerLoader from "../components/elements/ConfirmationInnerLoader";

const LiveChat: React.FC = () => {
  const params = useParams<{ token: string }>();

  return (
    <ConfirmationInnerLoader token={params.token} cache>
      <LiveChatLoader />
    </ConfirmationInnerLoader>
  );
};

export default LiveChat;
