import { UIClientFetchError, UISearchAiSummary, UIFetchStatus } from "@flights/types";

interface GenAiState {
  fetchStatus: UIFetchStatus;
  error?: UIClientFetchError;
  searchAiSummary?: UISearchAiSummary;
}

export const getInitialState = (): GenAiState => ({
  fetchStatus: "initial",
  error: undefined,
  searchAiSummary: {}
});

export type State = GenAiState;
