import React from "react";
import { useHiddenFeesController } from "./useHiddenFees.controller";
import { Icon, Stack, Text } from "@bookingcom/bui-react";
import { CheckmarkIcon } from "@bookingcom/bui-assets-react/streamline";

export function HiddenFees() {
  const { text } = useHiddenFeesController();

  if (!text) return null;

  return (
    <Stack direction="row" gap={2}>
      <Stack.Item>
        <Icon svg={CheckmarkIcon} mixin={{ marginBlockStart: 0.5 }} />
      </Stack.Item>
      <Stack.Item grow>
        <Text>{text}</Text>
      </Stack.Item>
    </Stack>
  );
}
