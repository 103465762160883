import { useMemo } from "react";
import { useI18n, t } from "@bookingcom/lingojs-react";
import { UIAirOrderAtolProtection, UIOrderStatus } from "@flights/types";

export type AtolControllerProps = {
  isProtectedCheckOut?: boolean;
  atolProtection?: UIAirOrderAtolProtection;
  orderStatus?: UIOrderStatus;
  isPbOrderDetails?: boolean;
};

export function useAtolController(props: AtolControllerProps) {
  const { isProtectedCheckOut, atolProtection, orderStatus, isPbOrderDetails } = props;
  const i18n = useI18n();
  const textMap = useMemo(
    () => ({
      protected: i18n.trans(t("flights_atol_price_breakdown")),
      notProtected: i18n.trans(t("flights_pb_price_breakdown_atol_not_protected")),
      pbProtected: i18n.trans(t("flights_pb_price_breakdown_atol_protected"))
    }),
    [i18n]
  );

  const text = useMemo(() => {
    if (
      atolProtection === "UNKNOWN" ||
      (isPbOrderDetails && orderStatus !== "CONFIRMED" && orderStatus !== "PENDING")
    ) {
      return "";
    }
    if (atolProtection === "PROTECTED" || atolProtection === "PROTECTED_BEFORE_EXEMPTION") {
      return textMap.pbProtected;
    }
    if (atolProtection === "NON_PROTECTED") {
      return textMap.notProtected;
    }
    if (isProtectedCheckOut) {
      return textMap.protected;
    }
    return "";
  }, [textMap, isProtectedCheckOut, atolProtection, orderStatus, isPbOrderDetails]);

  return { text };
}
