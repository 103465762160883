import React, { useMemo } from "react";
import { UIBrandedFare, UIFlightData } from "@flights/types";
import { Breakdown } from "./Breakdown";
import { Atol } from "./banners";
import { Stack } from "@bookingcom/bui-react";
import isHungaryDepartingRyanAirFlight from "utils/hungaryTaxes";
import useProfiler from "hooks/useProfiler";

export type UnifiedPriceBreakdownFarePriceProps = {
  fareOffer?: UIBrandedFare;
  baseOffer?: UIFlightData;
};

export function UnifiedPriceBreakdownFarePrice(props: UnifiedPriceBreakdownFarePriceProps) {
  const { baseOffer, fareOffer } = props;
  const { isSolo, isWithInfants, isWithKids } = useProfiler();

  const unifiedPriceBreakdown = useMemo(
    () => fareOffer?.unifiedPriceBreakdown || baseOffer?.unifiedPriceBreakdown,
    [baseOffer, fareOffer]
  );

  const segments = useMemo(() => baseOffer?.segments, [baseOffer]);

  const isHungaryDepartingRyanAir = useMemo(() => isHungaryDepartingRyanAirFlight(segments), [segments]);
  const isOneAdultTraveller = useMemo(
    () => isSolo() && !isWithInfants() && !isWithKids(),
    [isSolo, isWithInfants, isWithKids]
  );

  if (!unifiedPriceBreakdown) return null;

  return (
    <Stack gap={4}>
      <Breakdown
        {...unifiedPriceBreakdown}
        isOneAdultTraveller={isOneAdultTraveller}
        isHungaryDepartingRyanAir={isHungaryDepartingRyanAir}
      />
      <Atol />
    </Stack>
  );
}
