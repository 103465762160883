import React from "react";
import { Banner } from "@bookingcom/bui-react";
import { ClockIcon } from "@bookingcom/bui-assets-react/streamline/index.js";
import { Trans } from "@bookingcom/lingojs-react";

interface CheckinTimeInfoBannerProps {
  isLessThan24Hours: boolean;
}

export const CheckinTimeInfoBanner: React.FC<CheckinTimeInfoBannerProps> = ({ isLessThan24Hours }) => {
  return (
    <Banner
      variant="hint"
      startIcon={ClockIcon}
      text={
        isLessThan24Hours ? (
          <Trans
            tag="flights_pb_checkin_time_banner_open"
            components={[<strong key={0} />]}
            variables={{ start_bold: "<0>", end_bold: "</0>" }}
          />
        ) : (
          <Trans
            tag="flights_pb_checkin_time_banner_close"
            components={[<strong key={0} />]}
            variables={{ start_bold: "<0>", end_bold: "</0>" }}
          />
        )
      }
      dismissible={false}
    />
  );
};
