import React, { useMemo } from "react";
import { Divider, Stack, Text, Accordion, StackProps } from "@bookingcom/bui-react";
import {
  useBreakdownController,
  BreakdownControllerProps,
  type BreakdownScopeId,
  type BreakdownScopeItems,
  type BreakdownScope
} from "./useBreakdown.controller";
import styles from "./Breakdown.module.css";
import useFormatPrice from "hooks/useFormatPrice";

function SectionItems({ items, mixin }: { items: BreakdownScopeItems; mixin?: StackProps["mixin"] }) {
  const { formatPrice } = useFormatPrice();
  return useMemo(
    () =>
      items.map(({ id, items, price, title }, idx) => (
        <Stack mixin={mixin} gap={1} key={`${id}-${idx}`}>
          <Stack gap={0} direction="row">
            <Stack.Item grow>
              <Text variant="small_1" color="neutral_alt">
                {title}
              </Text>
            </Stack.Item>
            <Text
              variant="small_1"
              color={price.units < 0 || (price.units === 0 && price.nanos < 0) ? "constructive" : "neutral_alt"}
            >
              {formatPrice(price)}
            </Text>
          </Stack>
          {items.length ? <SectionItems mixin={{ paddingInlineStart: 2 }} items={items} /> : undefined}
        </Stack>
      )),
    [items, mixin, formatPrice]
  );
}

function AccordionItem({
  section,
  isOneAdultTraveller,
  sectionFooter
}: {
  section: BreakdownScope;
  isOneAdultTraveller: boolean;
  sectionFooter?: React.ReactNode;
}) {
  const { formatPrice } = useFormatPrice();
  return (
    <>
      <Stack.Item>
        <Text variant="strong_2">{section.name}</Text>
        {useMemo(
          () =>
            section.items.map((item, idx) => (
              <Accordion
                key={`${item.id}-${idx}`}
                className={styles.accordion}
                defaultActive={isOneAdultTraveller && section.id === "FLIGHT"}
                titleContent={
                  <Stack gap={0} direction="row">
                    <Stack.Item grow>
                      <Text variant="body_2">{item.title}</Text>
                    </Stack.Item>
                    <Text variant="body_2">{formatPrice(item.price)}</Text>
                  </Stack>
                }
              >
                {item.items.length ? <SectionItems mixin={{ paddingInlineEnd: 10 }} items={item.items} /> : undefined}
              </Accordion>
            )),
          [isOneAdultTraveller, formatPrice, section.id, section.items]
        )}
      </Stack.Item>
      {sectionFooter !== undefined ? <Stack.Item>{sectionFooter}</Stack.Item> : undefined}
      <Divider />
    </>
  );
}

export function Breakdown(props: BreakdownControllerProps) {
  const { isOneAdultTraveller } = props;
  const {
    totalPrice,
    totalPriceTitle,
    footerSections = {},
    sections = {},
    totalPriceFooter
  } = useBreakdownController(props);
  const { formatPrice } = useFormatPrice();
  const sortedIds: BreakdownScopeId[] = useMemo(() => ["FLIGHT", "EXTRA", "FEE", "DISCOUNT"], []);
  const sortedSections = useMemo<BreakdownScope[]>(
    () => sortedIds.map((id) => sections?.[id]).filter((section): section is BreakdownScope => Boolean(section)),
    [sections, sortedIds]
  );

  return (
    <Stack direction="column" gap={2}>
      {useMemo(
        () =>
          sortedSections.map((section, idx) => (
            <AccordionItem
              key={`${section.id}-${idx}`}
              section={section}
              sectionFooter={footerSections?.[section.id]}
              isOneAdultTraveller={isOneAdultTraveller}
            />
          )),
        [sortedSections, footerSections, isOneAdultTraveller]
      )}
      <Stack.Item>
        <Stack gap={0}>
          <Stack direction="row" gap={0}>
            <Stack.Item grow>
              <Text variant="headline_3">{totalPriceTitle}</Text>
            </Stack.Item>
            <Stack direction="column" gap={0}>
              <Text variant="headline_3">{formatPrice(totalPrice)}</Text>
            </Stack>
          </Stack>
          <Text variant="body_2">{totalPriceFooter}</Text>
        </Stack>
      </Stack.Item>
    </Stack>
  );
}
