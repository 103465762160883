import Frame from "../../../components/elements/Frame";
import React from "react";
import { Button, Card, Divider, Image, Stack, Text } from "@bookingcom/bui-react";
import { t, useI18n } from "@bookingcom/lingojs-react";
import { useSelector } from "react-redux";
import esimSelectors from "store/esim/selectors";
import styles from "./EsimBanner.module.css";
import flights_ace_web_esim_banner from "utils/experiments/ace/flights_ace_web_esim_banner";
import { UIEsimContext, UIOrderStatus } from "@flights/types";
import { useEsimClickBanner, useTrackEsimShowBanner } from "ancillaries/hooks/Esim/useEsimTrackV2";

interface EsimBannerProps {
  context: UIEsimContext;
  orderStatus?: UIOrderStatus;
}

const EsimBanner = ({ orderStatus, context }: EsimBannerProps) => {
  const i18n = useI18n();
  const { getHeader, getSubheader, getCaption, getDiscountLink } = getBannerPropsFromContext(context);
  const header = useSelector(getHeader);
  const subheader = useSelector(getSubheader);
  const caption = useSelector(getCaption);
  const discountLink = useSelector(getDiscountLink);
  const showButton = ["index", "preOrder", "order"].includes(context) && !!discountLink && orderStatus === "CONFIRMED";
  const trackEsimClickBanner = useEsimClickBanner(context);

  const handleButtonClick = () => {
    flights_ace_web_esim_banner.goals.clicked_esim_banner_cta();
    trackEsimClickBanner(showButton);
    window.open(discountLink, "_blank", "noopener noreferrer");
  };

  useTrackEsimShowBanner(showButton, context);

  return (
    <Card>
      <Stack>
        <Frame mb={1} direction="row" alignItems="start">
          <Frame pr={3} pt={1}>
            <Image
              width="64px"
              height="64px"
              alt="Genius Esim Benefit"
              asset={{
                setName: "illustrations-traveller",
                assetName: "GeniusEsimBenefit"
              }}
              contentMode="fit"
            />
          </Frame>
          <Stack>
            <Text variant="strong_1">{header}</Text>
            <Text variant="body_1">{subheader}</Text>
            <Stack>
              <Text variant="small_1">{caption}</Text>
              {showButton ? (
                <Button variant="secondary" size="medium" onClick={handleButtonClick} className={styles.discountButton}>
                  {i18n.trans(t("flights_genius_esim_cta"))}
                </Button>
              ) : null}
            </Stack>
          </Stack>
        </Frame>
        <Divider />
        <Frame mt={1} direction="row" justifyContent="space-between" alignItems="center">
          <Text variant="small_2" color="neutral_alt">
            {i18n.trans(t("flights_xsell_genius_banner_footer"))}
          </Text>
          <Image
            asset={{
              setName: "images-genius",
              assetName: "GeniusLogo"
            }}
            height="14px"
            width="54px"
            alt="Genius Logo"
            contentMode="fit"
          />
        </Frame>
      </Stack>
    </Card>
  );
};

function getBannerPropsFromContext(context: UIEsimContext) {
  switch (context) {
    case "index":
      return {
        getHeader: esimSelectors.getHeaderIndex,
        getSubheader: esimSelectors.getSubheaderIndex,
        getCaption: esimSelectors.getCaptionIndex,
        getDiscountLink: esimSelectors.getDiscountLinkIndex
      };

    case "search":
      return {
        getHeader: esimSelectors.getHeaderSearch,
        getSubheader: esimSelectors.getSubheaderSearch,
        getCaption: esimSelectors.getCaptionSearch,
        getDiscountLink: esimSelectors.getDiscountLinkSearch
      };

    case "flight":
      return {
        getHeader: esimSelectors.getHeaderFlight,
        getSubheader: esimSelectors.getSubheaderFlight,
        getCaption: esimSelectors.getCaptionFlight,
        getDiscountLink: esimSelectors.getDiscountLinkFlight
      };

    case "preOrder":
      return {
        getHeader: esimSelectors.getHeaderPreOrder,
        getSubheader: esimSelectors.getSubheaderPreOrder,
        getCaption: esimSelectors.getCaptionPreOrder,
        getDiscountLink: esimSelectors.getDiscountLinkPreOrder
      };

    case "order":
      return {
        getHeader: esimSelectors.getHeaderOrder,
        getSubheader: esimSelectors.getSubheaderOrder,
        getCaption: esimSelectors.getCaptionOrder,
        getDiscountLink: esimSelectors.getDiscountLinkOrder
      };

    default:
      return {
        getHeader: esimSelectors.getHeaderSearch,
        getSubheader: esimSelectors.getSubheaderSearch,
        getCaption: esimSelectors.getCaptionSearch,
        getDiscountLink: esimSelectors.getDiscountLinkSearch
      };
  }
}

export default EsimBanner;
