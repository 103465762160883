import { UIFlightSegment } from "@flights/types";
import { getCountries } from "@flights/content-universal";
const isHungaryDepartingRyanAirFlight = (segments?: UIFlightSegment[]): boolean => {
  let isHungaryDepartingRyanAir = false;

  segments?.forEach((segment) => {
    return segment.legs.forEach((leg, index) => {
      // There are 2 cases for showing tax info
      // - Case 1: The first leg departing from Hungary with Ryanair carrier
      // - Case 2: The leg is a self-transfer transit departing from Hungary with Ryanair carrier

      const hungaryCountryObject = getCountries().find((country) => country.name === "Hungary");

      if (index === 0 || leg.vi?.protected) {
        const departureFromHungary = leg.departureAirport.country === hungaryCountryObject?.code;
        const isRyanairAirline = leg.carriersData.some((carrier) => {
          return carrier.name === "Ryanair";
        });

        if (departureFromHungary && isRyanairAirline) {
          isHungaryDepartingRyanAir = true;
          return;
        }
      }
    });
  });

  return isHungaryDepartingRyanAir;
};

export default isHungaryDepartingRyanAirFlight;
