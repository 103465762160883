// eslint-disable-next-line import/no-deprecated
import { __deprecated__createTrackingUtilsV2 } from "utils/experiments";

// eslint-disable-next-line import/no-deprecated
const utils = __deprecated__createTrackingUtilsV2({
  name: "flights_apex_web_insurance_on_flight_details",
  stages: {
    fd_loaded: 1,
    last_line_of_extras_visible: 2,
    other_ancillaries_visible: 3,
    extras_title_visible: 4,
    desktop: 5,
    mdot: 6,
    multi_traveller: 7,
    single_traveller: 8
  },
  goals: {
    select_flight_details_next: 1,
    ancillary_action_click_with_insurance: 2
  },
  goalsWithValue: []
});

const trackStages = (isMobile: boolean, travellers: string[]) => {
  utils.stages.fd_loaded();

  if (isMobile) utils.stages.mdot();
  if (!isMobile) utils.stages.desktop();

  if (travellers.length === 1) utils.stages.single_traveller();
  if (travellers.length > 1) utils.stages.multi_traveller();
};

export default { ...utils, trackStages };
