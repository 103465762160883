import React, { useMemo } from "react";
import { Text } from "@bookingcom/bui-react";
import { useAtolController } from "./useAtol.controller";
import useATOL from "hooks/useATOL";
import useRouteName from "hooks/useRouteName";
import { useSelector } from "react-redux";
import { getOrder } from "store/order/selectors";

export function Atol() {
  const { isATolProtectedCheckOut } = useATOL();
  const routeName = useRouteName();
  const order = useSelector(getOrder);
  const isPbOrderDetails = useMemo(() => routeName === "pb-order-details", [routeName]);
  const orderStatus = useMemo(() => order?.orderStatus, [order]);
  const atolProtection = useMemo(() => order?.airOrder?.atolProtection, [order]);
  const isProtectedCheckOut = useMemo(() => isATolProtectedCheckOut(), [isATolProtectedCheckOut]);

  const { text } = useAtolController({ isProtectedCheckOut, atolProtection, orderStatus, isPbOrderDetails });

  if (!text) return null;

  return (
    <Text variant="small_1" attributes={{ "data-testid": "ATOL_price_breakdown_footer_text" }}>
      {text}
    </Text>
  );
}
