/* eslint-disable no-restricted-syntax -- this line was auto generated, hence fix the issue timely */
import { UIAvailableExtraProducts, UIBrandedFareInfo, UIIncludedProductBySegmentCompressed } from "@flights/types";
import React, { useMemo } from "react";
import { getFeaturesToRender } from "../CheckoutFareInner/utils/features";
import flights_apex_web_move_baggage_features_to_server from "utils/experiments/apex/flights_apex_web_move_baggage_features_to_server";
import { getBaggageFeaturesToRender } from "@flights/ancillaries";
import FareFeatureIcon from "../FareFeatureIcon/FareFeatureIcon";
import { useI18n } from "@bookingcom/lingojs-react";
import { Stack } from "@bookingcom/bui-react";

type Props = {
  includedProducts?: UIIncludedProductBySegmentCompressed;
  brandedFareInfo: UIBrandedFareInfo;
  limit?: number;
  isLarge?: boolean;
  isCompact?: boolean;
  includeSellableFeatures?: boolean;
  withAvailabilityIcons?: boolean;
  ancillaries?: UIAvailableExtraProducts;
  isPreCheckAndPay?: boolean;
};

const FareFeatureIcons = ({
  includedProducts,
  ancillaries,
  brandedFareInfo,
  limit,
  isLarge,
  isCompact,
  includeSellableFeatures,
  isPreCheckAndPay,
  withAvailabilityIcons
}: Props) => {
  const i18n = useI18n();
  const { includedFeatures } = useMemo(
    () => getFeaturesToRender(brandedFareInfo, { includeSellableFeatures, isPreCheckAndPay }),
    [brandedFareInfo, includeSellableFeatures, isPreCheckAndPay]
  );

  const baggageFeaturesToRender = useMemo(() => {
    return !!flights_apex_web_move_baggage_features_to_server.trackWithDefaultStage()
      ? { includedBaggageFeatures: [], excludedBaggageFeatures: [] }
      : getBaggageFeaturesToRender(i18n, includedProducts, ancillaries, {
          includeNonIncludedBaggage: includeSellableFeatures
        });
  }, [includedProducts, i18n, ancillaries, includeSellableFeatures]);

  const fareFeaturesIcons = [...baggageFeaturesToRender.includedBaggageFeatures, ...includedFeatures]
    .slice(0, limit)
    .map(({ icon, name, availability }) => (
      <FareFeatureIcon
        key={name}
        icon={icon}
        isLarge={isLarge}
        availability={availability}
        withAvailabilityIcon={withAvailabilityIcons}
        inline={true}
      />
    ));

  if (!fareFeaturesIcons.length) {
    return null;
  }

  return (
    <Stack attributes={{ "data-testid": "FareFeaturesIcons" }} direction="row" gap={isLarge ? 0 : isCompact ? 0.5 : 2}>
      {fareFeaturesIcons}
    </Stack>
  );
};

export default FareFeatureIcons;
