import { ActionsUnion, createAction } from "@bookingcom/flights-core";
import { UIClientFetchError, UISearchAiSummary } from "@flights/types";

export enum ActionTypes {
  fetch = "searchAiSummary/fetch",
  fetchSuccess = "searchAiSummary/fetchSuccess",
  fetchError = "searchAiSummary/fetchError"
}

export const actions = {
  fetch: createAction(() => ({
    type: ActionTypes.fetch,
    payload: {}
  })),

  fetchSuccess: createAction((data: UISearchAiSummary) => ({
    type: ActionTypes.fetchSuccess,
    payload: { data }
  })),

  fetchError: createAction((error: UIClientFetchError) => ({
    type: ActionTypes.fetchError,
    payload: { error }
  }))
};

export type Actions = ActionsUnion<typeof actions>;
