import { useMemo } from "react";
import { SBCabinClass, SBSearchSegment, SBSearchType, SBState } from "@bookingcom/flights-searchbox";
import useEvents from "./useEvents";

function convertLastSearchItemIntoSBSearchSegment(searchItem: SearchHistory): SBSearchSegment[] | undefined {
  if (
    !searchItem.from ||
    !searchItem.to ||
    !searchItem.fromCountry ||
    !searchItem.toCountry ||
    !searchItem.fromLocationName ||
    !searchItem.toLocationName
  )
    return;

  switch (searchItem.type) {
    case "ONEWAY":
    case "ROUNDTRIP":
      const [fromCode, fromType] = searchItem.from.split(".");
      const [toCode, toType] = searchItem.to.split(".");

      return [
        {
          from: [
            {
              type: fromType,
              code: fromCode,
              name: searchItem.fromLocationName,
              city: fromCode,
              cityName: searchItem.fromLocationName,
              country: searchItem.fromCountry
            }
          ],
          to: [
            {
              type: toType,
              code: toCode,
              name: searchItem.toLocationName,
              city: toCode,
              cityName: searchItem.toLocationName,
              country: searchItem.toCountry
            }
          ],
          departureDate: searchItem.depart,
          returnDate: searchItem.return
        }
      ];
    case "MULTISTOP":
      if (!searchItem.multiStopDates) return;
      const from = searchItem.from.split("|").map((location) => location.split("."));
      const to = searchItem.to.split("|").map((location) => location.split("."));
      const fromLocationNames = searchItem.fromLocationName.split("|");
      const toLocationNames = searchItem.toLocationName.split("|");
      const departDates = searchItem.multiStopDates.split("|");
      const fromCountries = searchItem.fromCountry.split("|");
      const toCountries = searchItem.toCountry.split("|");

      return [...new Array(from.length)].map((_value, tripIndex) => ({
        from: [
          {
            type: from[tripIndex][1],
            code: from[tripIndex][0],
            name: fromLocationNames[tripIndex],
            city: from[tripIndex][0],
            cityName: fromLocationNames[tripIndex],
            country: fromCountries[tripIndex]
          }
        ],
        to: [
          {
            type: to[tripIndex][1],
            code: to[tripIndex][0],
            name: toLocationNames[tripIndex],
            city: to[tripIndex][0],
            cityName: toLocationNames[tripIndex],
            country: toCountries[tripIndex]
          }
        ],
        departureDate: departDates[tripIndex]
      }));
    default:
      return;
  }
}

export function useLatestSearch(
  searchBoxInitialValue: SBState,
  searchHistory: SearchHistory[],
  isEligible: boolean
): SBState {
  const { grumble } = useEvents();

  return useMemo(() => {
    if (searchHistory?.length > 0 && isEligible) {
      try {
        const lastSearchItem = searchHistory[0];
        const searchSegments = convertLastSearchItemIntoSBSearchSegment(lastSearchItem);
        if (!searchSegments) {
          grumble("Incorrect value in useLatestSearch", {
            error: "Could not convert search segments",
            lastSearchItem: searchHistory[0]
          });
          return searchBoxInitialValue;
        }

        return {
          type: lastSearchItem.type as SBSearchType,
          searchSegments,
          adults: Number(lastSearchItem.adults),
          children: lastSearchItem.children ? lastSearchItem.children.split(",").map((child) => Number(child)) : [0],
          cabinClass: lastSearchItem.cabinClass as SBCabinClass,
          isDirect: lastSearchItem.stops === "0",
          fareCalendar: searchBoxInitialValue.fareCalendar
        };
      } catch (error) {
        grumble("Incorrect value in useLatestSearch", {
          error,
          lastSearchItem: searchHistory[0]
        });
      }
    }

    return searchBoxInitialValue;
  }, [searchBoxInitialValue, searchHistory, isEligible, grumble]);
}
