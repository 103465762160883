import { getCookie, setCookie } from "utils/cookies";
import { UISearchSegment } from "store/search/state";
import { UIFlightSegment, UILocation, UIOrder } from "@flights/types";
import { CsxpPlacementNames, CsxpPlacementPages, CsxpVerticals } from "./constants";
import { FlightContext } from "@bookingcom/cross-sell-loader-react/dist/CsxpWidget/useConfigValidation";
import { BOOKING_DEFAULT_AFFILIATE_ID, BOOKING_DEFAULT_LABEL } from "@flights/constants";

export const percentageDiscount = (geniusLevel: number | undefined) =>
  geniusLevel === 3 ? "20" : geniusLevel === 2 ? "15" : "10";

export const isTripInPast = (flightSegments: UIFlightSegment[]) => {
  if (!flightSegments.length) return false;
  const today = new Date().toISOString();
  const lastSegment = flightSegments[flightSegments.length - 1];
  return lastSegment.arrivalTime < today;
};

export const isXsellRecList = (orderId: string) => {
  const cookie = getCookie("xSellRecList");

  if (cookie) {
    const parsedCookie = JSON.parse(cookie);
    return parsedCookie.some((id: string) => id === orderId);
  }

  return false;
};

export const neverShowXsellRecList = (orderId: string) => {
  const oldCookieValue = getCookie("xSellRecList") || "[]";
  const newCookieValue = [...JSON.parse(oldCookieValue), orderId];
  try {
    setCookie("xSellRecList", JSON.stringify(newCookieValue), { maxAge: "1y" });
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
  }
};

type FlightData = {
  flightContext: FlightContext | undefined;
  currencyCode: string | undefined;
  reserveOrderId: string | undefined;
};

export const buildflightData = ({
  order,
  routeName,
  searchSegments,
  searchType,
  adults,
  childrensAges
}: {
  order?: UIOrder;
  routeName: string;
  searchSegments?: UISearchSegment[];
  searchType?: string;
  adults?: number;
  childrensAges?: number[];
}) => {
  const flightData: FlightData = {
    flightContext: undefined,
    currencyCode: undefined,
    reserveOrderId: undefined
  };

  if ((!order || !order.orderId || routeName === "checkout3") && adults && searchSegments) {
    flightData.flightContext = {
      adults,
      children: childrensAges,
      trip_type: searchType,
      searchSegments: searchSegments.map((segment: UISearchSegment) => ({
        from: segment.from.map((from: UILocation) => (from.type === "AIRPORT" ? from.city : from.code)),
        to: segment.to.map((to: UILocation) => (to.type === "AIRPORT" ? to.city : to.code)),
        departureDate: segment.departureDate,
        returnDate: segment.returnDate
      }))
    };
    flightData.currencyCode = order?.orderCurrency;
  } else {
    flightData.currencyCode = order?.orderCurrency;
    flightData.reserveOrderId = order?.orderId;
  }

  return flightData;
};

export const buildCsxpWidgetProps = ({
  order,
  page,
  placement,
  aid,
  label,
  isMobile,
  locale,
  currencyCode,
  reserveOrderId,
  isRTL,
  env,
  flightContext
}: {
  order?: UIOrder;
  page: CsxpPlacementPages;
  placement: CsxpPlacementNames;
  aid: number;
  label: string;
  isMobile: boolean;
  locale?: string;
  currencyCode?: string;
  reserveOrderId?: string;
  isRTL: boolean;
  env?: string;
  flightContext?: FlightContext;
}) => {
  const sessionAid = Number(aid || BOOKING_DEFAULT_AFFILIATE_ID);
  const sessionLabel = label || BOOKING_DEFAULT_LABEL;
  const isPastTrip = order?.airOrder.flightSegments ? isTripInPast(order?.airOrder.flightSegments) : false;

  // Required to have the page, placement and either a reserve Order or the flight context, and trip must be in future
  if (!page || !placement || (!reserveOrderId && !flightContext) || isPastTrip) {
    return null;
  }

  return {
    config: {
      platform: isMobile ? ("mdot" as const) : ("ddot" as const),
      aid: sessionAid,
      label: sessionLabel,
      languageCode: locale || "en-us",
      currencyCode: currencyCode ? currencyCode : "EUR",
      placementPage: page,
      exposurePointData: {
        placement_name: placement,
        reservations: [
          {
            vertical: CsxpVerticals.FLIGHTS,
            reserve_order_id: reserveOrderId
          }
        ]
      },
      isRtl: isRTL,
      isDev: env !== "prod",
      flightContext: flightContext
    },
    onError: (error: any) => {
      throw error;
    }
  };
};

export const getCsxpSkeletonItemsByPlacementPlatform = function (
  placementPage: CsxpPlacementPages | undefined,
  placementName: CsxpPlacementNames | undefined,
  platform: string
): number {
  switch (true) {
    case placementPage === CsxpPlacementPages.FLIGHTS_CONFIRMATION &&
      placementName === CsxpPlacementNames.MODAL &&
      platform === "DDOT":
      return 3;
    case placementPage === CsxpPlacementPages.FLIGHTS_CONFIRMATION &&
      placementName === CsxpPlacementNames.MODAL &&
      platform === "MDOT":
      return 2;
    case placementPage === CsxpPlacementPages.FLIGHTS_CONFIRMATION &&
      placementName === CsxpPlacementNames.PLAN_AHEAD &&
      platform === "DDOT":
      return 3;
    case placementPage === CsxpPlacementPages.FLIGHTS_CONFIRMATION &&
      placementName === CsxpPlacementNames.PLAN_AHEAD &&
      platform === "MDOT":
      return 2;
    case placementPage === CsxpPlacementPages.FLIGHT_MANAGE_BOOKING &&
      placementName === CsxpPlacementNames.MODAL &&
      platform === "DDOT":
      return 3;
    case placementPage === CsxpPlacementPages.FLIGHT_MANAGE_BOOKING &&
      placementName === CsxpPlacementNames.MODAL &&
      platform === "MDOT":
      return 2;
    case placementPage === CsxpPlacementPages.FLIGHT_MANAGE_BOOKING &&
      placementName === CsxpPlacementNames.PLAN_AHEAD &&
      platform === "DDOT":
      return 3;
    case placementPage === CsxpPlacementPages.FLIGHT_MANAGE_BOOKING &&
      placementName === CsxpPlacementNames.PLAN_AHEAD &&
      platform === "MDOT":
      return 2;
    default:
      return 1;
  }
};
