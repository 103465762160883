import { useMemo } from "react";
import { useI18n, t } from "@bookingcom/lingojs-react";
import { INSTALLMENTS_FAQ_URL } from "@flights/constants";

export function useInstallmentsController() {
  const i18n = useI18n();
  const text = useMemo(() => i18n.trans(t("flights_price_breakdown_sr_instalment_message")), [i18n]);
  const href = useMemo(() => `${INSTALLMENTS_FAQ_URL}?lang=${i18n.language}`, [i18n]);
  const cta = useMemo(() => i18n.trans(t("flights_price_breakdown_sr_instalment_cta")), [i18n]);
  return { text, href, cta };
}
