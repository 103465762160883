import { createTrackingUtils } from "../index";
import { useMDotValueDefaultConditions, MDotValueStage } from "./default_stages";
import useUserAgent from "hooks/useUserAgent";
import { noop } from "lodash";
import { useHistory, useLocation } from "react-router-dom";
import { isOfMetaOrigin } from "utils/marketing-url-params";

const EXP_NAME = "flights_mdotvalue_flight_details_back_button";

const expTrackingUtils = createTrackingUtils({
  name: EXP_NAME,
  defaultStage: 1,
  stages: {
    [MDotValueStage.USER_ELIGIBLE]: 1,
    fromSr: 2,
    [MDotValueStage.LANDING_USER]: 3,
    [`${MDotValueStage.LANDING_USER} + ${MDotValueStage.PAID_TRAFFIC}`]: 4,
    [MDotValueStage.US_TRAFFIC]: 5,
    [MDotValueStage.KEY_MARKET_TRAFFIC]: 6,
    [MDotValueStage.USER_SIGNED_IN]: 7,
    [MDotValueStage.USER_SIGNED_OUT]: 8,
    [MDotValueStage.DIRECT_TRAFFIC]: 9
  },
  goals: {
    onFlightExit: 1,
    onNextStep: 2,
    onBackToSr: 3,
    onBackToSrClick: 4
  },
  goalsWithValue: []
});

const useUserEligibility = () => {
  const { isMobile } = useUserAgent();
  return isMobile && !isOfMetaOrigin();
};

const useTrackStages = () => {
  const history = useHistory();
  const location = useLocation();
  const stagingConditions: Map<MDotValueStage | string, boolean> = useMDotValueDefaultConditions();
  const isUserEligible = useUserEligibility();

  if (!isUserEligible) {
    return noop;
  }

  return () => {
    stagingConditions.set("fromSr", (location.state as { fromSearch: boolean })?.fromSearch);
    stagingConditions.set("landingUser", history.length <= 1);

    stagingConditions.set(
      `${MDotValueStage.LANDING_USER} + ${MDotValueStage.PAID_TRAFFIC}`,
      Boolean(stagingConditions.get(MDotValueStage.LANDING_USER) && stagingConditions.get(MDotValueStage.PAID_TRAFFIC))
    );

    stagingConditions.forEach((value, conditionName) => {
      if (value && conditionName in expTrackingUtils.stages) {
        expTrackingUtils.stages[conditionName as keyof typeof expTrackingUtils.stages]();
      }
    });
  };
};

export default {
  ...expTrackingUtils,
  useUserEligibility,
  useTrackStages
};
