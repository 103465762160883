import countriesJSON from "./countries.json";
export const getCountries = () => {
    return countriesJSON;
};
export const getCountry = (code) => {
    const country = countriesJSON.find((country) => country.code === code);
    if (!country) {
        console.error(`content/getCountry: country not found for code ${code}`);
    }
    return country;
};
/**
 * Checks if a provided country code is valid among global country codes
 * @param code country code to be checked
 * @returns whether it is a valid country or not
 */
export const isValidCountry = (code) => {
    if (!code) {
        return false;
    }
    return !!countriesJSON.find((country) => country.code === code.toUpperCase());
};
