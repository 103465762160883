import React from "react";
import { useInstallmentsController } from "./useInstallments.controller";
import { CreditCardBackIcon } from "@bookingcom/bui-assets-react/streamline";
import { Banner, Link, Text } from "@bookingcom/bui-react";
import useGlobalContext from "hooks/useGlobalContext";

export function Installments() {
  const { ipCountry } = useGlobalContext();
  const { text, href, cta } = useInstallmentsController();

  if (ipCountry !== "br") return null;

  return (
    <Banner dismissible={false} startIcon={CreditCardBackIcon}>
      <Text variant="body_2">{text}</Text>
      <Link href={href} variant="primary" attributes={{ target: "_blank", rel: "noreferrer" }}>
        {cta}
      </Link>
    </Banner>
  );
}
