import React from "react";

import { Text } from "@bookingcom/bui-react";
import { useI18n, I18nChildContext } from "@bookingcom/lingojs-react";
import { t } from "@bookingcom/lingojs-core";

import useGlobalContext from "../hooks/useGlobalContext";
import styles from "./NoJS.module.css";
import { trackWithDefaultStage } from "@flights/et-universal";

export default function NoJS() {
  const i18n = useI18n() as I18nChildContext;
  const { env, userAgent } = useGlobalContext();

  return env === "prod" && !userAgent.isBot ? (
    <noscript className={styles.root}>
      <Text variant={!!trackWithDefaultStage("flights_web_cat_winter_deals_promo", 1) ? "headline_3" : "headline_1"}>
        {i18n.trans(t("flights_error_js_header"))}
      </Text>
      <Text variant={!!trackWithDefaultStage("flights_web_cat_winter_deals_promo", 1) ? "featured_2" : "body_2"}>
        {i18n.trans(t("flights_error_js_sub"))}
      </Text>
    </noscript>
  ) : (
    <></>
  );
}
