import React, { useEffect, useRef } from "react";
import FlightCardMobile from "./components/FlightCardBound";
import FlightCardDesktop, { FlightCardBoundProps } from "./components/FlightCardBound.desktop";
import useUserAgent from "hooks/useUserAgent";
import { trackCustomGoal } from "utils/et";
import Frame from "../Frame";
import useTrackViAA from "hooks/useTrackViAA";
import useIsInViewport from "hooks/useIsInViewport";
import { t, useI18n } from "@bookingcom/lingojs-react";
import flights_mdotvalue_sr_retain_scroll_on_close_flight_details from "utils/experiments/mdotvalue/flights_mdotvalue_sr_retain_scroll_on_close_flight_details";
import { useStickySearchBoxExp } from "../../../utils/experiments/mdotvalue/flights_mdotvalue_sr_sticky_search_box";
import flights_web_reduce_spacing_sr_ddot from "utils/experiments/funnel/flights_web_reduce_spacing_sr_ddot";

type Props = FlightCardBoundProps;

const _FlightCard = (props: Props) => {
  const { flight, index, onClick, bigLogo, searchType, mt, mb, brandedFaresShownByDefault } = props;
  const { isMobile } = useUserAgent();
  const FlightCard = isMobile || props.isAlternativeFareCard ? FlightCardMobile : FlightCardDesktop;
  const i18n = useI18n();
  const { trackScrollToThird, trackScrollToFifth } = useStickySearchBoxExp(); //@exp flights_mdotvalue_sr_sticky_search_box

  //@start flights_mdotvalue_sr_sticky_search_box flights_web_aa_virtual_interlining_tracking
  const ref = useRef<HTMLDivElement>(null);
  const isInViewport = useIsInViewport(ref.current);
  //@end flights_mdotvalue_sr_sticky_search_box flights_web_aa_virtual_interlining_tracking

  // flights_web_aa_virtual_interlining_tracking -- start
  const trackViAA = useTrackViAA();
  useEffect(() => {
    trackViAA.ViAAInStage1();
    if (!isInViewport) return;
    trackViAA.ViAAInStage2(flight);
  }, [isInViewport, trackViAA, flight]);
  // flights_web_aa_virtual_interlining_tracking -- end

  // start - flights_mdotvalue_sr_retain_scroll_on_close_flight_details
  const [isPastFirstCard, setPastFirstCard] = React.useState(false);
  useEffect(() => {
    if (index > 0 || isInViewport || isPastFirstCard) {
      return;
    }

    // We are detecting if the element is out of the viewport after scrolling.
    // useIsInViewport returns false by default,
    // thus on page load it tells us it is not in the viewport.
    const elementBoundingRect = ref.current?.getBoundingClientRect();
    const _isPastFirstCard = Boolean(elementBoundingRect && elementBoundingRect.bottom < 0);
    setPastFirstCard(_isPastFirstCard);
  }, [isInViewport, index, isPastFirstCard]);
  const trackMdotValueSrRetainScrollOnCloseFlightDetails =
    flights_mdotvalue_sr_retain_scroll_on_close_flight_details.useTracking(index, isPastFirstCard);
  // end - flights_mdotvalue_sr_retain_scroll_on_close_flight_details

  //@start flights_mdotvalue_sr_sticky_search_box
  useEffect(() => {
    if (isInViewport && index === 3) {
      trackScrollToThird();
    } else if (isInViewport && index === 5) {
      trackScrollToFifth();
    }
  }, [index, isInViewport, trackScrollToFifth, trackScrollToThird]);
  //@end flights_mdotvalue_sr_sticky_search_box

  /** start - flights_web_reduce_spacing_sr_ddot */
  const calculateMargins = () => {
    if (!isMobile && flights_web_reduce_spacing_sr_ddot.trackWithDefaultStage()) {
      return {
        mt: 0,
        mb: 0
      };
    }

    return {
      mt: 2,
      mb: 4
    };
  };

  const margins = calculateMargins();
  /** end - flights_web_reduce_spacing_sr_ddot */

  return (
    <Frame
      mt={mt ?? margins.mt}
      mb={mb ?? margins.mb}
      elementRef={ref}
      attributes={{ role: "group", "aria-label": i18n.trans(t("a11y_flights_search_card_begin")) }}
    >
      <FlightCard
        flight={flight}
        onClick={() => {
          if (isMobile) {
            trackCustomGoal("flight_web_sr_traffic_aa_mdot", 1);
            trackMdotValueSrRetainScrollOnCloseFlightDetails(); // flights_mdotvalue_sr_retain_scroll_on_close_flight_details
          }
          trackViAA.ViAAInStage3(flight);
          trackViAA.ViAAInStage4(flight);
          onClick(flight);
        }}
        pricePerPerson
        index={index}
        bigLogo={bigLogo}
        searchType={searchType}
        /* start - flights_alternative_fares_for_sold_out_errors_web */
        forceHideLastAvailableSeats={props.forceHideLastAvailableSeats}
        isAlternativeFareCard={props.isAlternativeFareCard}
        /* end - flights_alternative_fares_for_sold_out_errors_web */
        brandedFaresShownByDefault={brandedFaresShownByDefault}
      />
    </Frame>
  );
};

const FlightCard = React.memo(_FlightCard);

export default FlightCard;
