import { UIFlightData, UIProductSelection } from "@flights/types";
import { flightsSessionStore } from "@flights/web-api-utils-universal";

export type SessionStorageOfferProperties = {
  ancillaries: UIProductSelection;
};
type SessionStorageOfferData = Partial<SessionStorageOfferProperties>;
export type SessionStorageOfferToken = UIFlightData["token"];
type SessionStorageOffer = Partial<Record<SessionStorageOfferToken, SessionStorageOfferData>>;

const key = "offers_checkout_data";
const expirationTime = "30m";

const getSessionStorageData = () => (flightsSessionStore.get(key) || {}) as SessionStorageOffer;

export const getOfferCheckoutData = (offerToken: SessionStorageOfferToken) =>
  getSessionStorageData()[offerToken] || undefined;

type UpdateFn = (currentState: SessionStorageOfferData) => Partial<SessionStorageOfferData>;

export const setOfferCheckoutData = (
  offerToken: SessionStorageOfferToken,
  stateOrUpdateFn: SessionStorageOfferData | UpdateFn
) => {
  const storeData = getSessionStorageData();

  if (typeof stateOrUpdateFn === "function") {
    const currentData = getOfferCheckoutData(offerToken) || {};
    storeData[offerToken] = stateOrUpdateFn(currentData);
  } else {
    storeData[offerToken] = stateOrUpdateFn;
  }

  flightsSessionStore.set(key, storeData, { ttl: expirationTime });
};
