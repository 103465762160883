import React, { FC, useState, useMemo } from "react";
import { Banner, Icon, SheetContainer, Text, Spinner, Stack } from "@bookingcom/bui-react";
import { SparklesIcon } from "@bookingcom/bui-assets-react/streamline";
import { t } from "@bookingcom/lingojs-core";
import { useI18n } from "@bookingcom/lingojs-react";

import styles from "./GenAiSearchSummary.module.css";

const GenAiSearchSummary: FC<{
  className?: string;
  text?: string;
  isLoading?: boolean;
  isError?: boolean;
  onBannerCtaClick?: VoidFunction;
  onBannerClose?: VoidFunction;
  onModalClose?: VoidFunction;
}> = ({ className, text, isLoading, isError, onBannerCtaClick, onBannerClose, onModalClose }) => {
  const i18n = useI18n();
  const [bannerVisible, setBannerVisible] = useState(true);
  const [modalVisible, setModalVisible] = useState(false);

  return (
    <>
      {bannerVisible && (
        <Banner
          className={className}
          title={i18n.trans(t("flights_sr_genai_search_summary_title"))}
          titleTagName="h2"
          startIcon={
            <div className={styles.bannerIconHolder}>
              <Icon svg={SparklesIcon} size="medium" color="action" />
            </div>
          }
          actions={[
            {
              text: i18n.trans(t("flights_sr_genai_search_summary_cta")),
              className: styles.bannerActionButton,
              onClick: () => {
                setModalVisible(true);
                onBannerCtaClick?.();
              }
            }
          ]}
          dismissible={true}
          onClose={() => {
            setBannerVisible(false);
            onBannerClose?.();
          }}
          closeAriaLabel={i18n.trans(t("a11y_flights_sr_genai_search_summary_close"))}
        >
          <Text variant="body_2">{i18n.trans(t("flights_sr_genai_search_summary_subtitle"))}</Text>
        </Banner>
      )}

      <SheetContainer
        active={modalVisible}
        position="center"
        title={i18n.trans(t("flights_sr_genai_modal_search_summary_heading"))}
        onCloseTrigger={() => {
          setModalVisible(false);
          onModalClose?.();
        }}
        closeAriaLabel={i18n.trans(t("a11y_flights_sr_genai_summary_close"))}
      >
        <div className={styles.modalContent}>
          {isError ? <ModalError /> : isLoading ? <ModalLoading /> : <ModalContent text={text || ""} />}
        </div>
      </SheetContainer>
    </>
  );
};

const ModalContent: FC<{ text: string }> = ({ text }) => {
  // This is temporary workaround, and must be revisited after GenAI search summary MVP,
  // when `flights_web_sr_gen_ai_summary_desktop` learning exp is over.
  // We should find a proper way to template this string from ML - an alternative approach is to be discussed by Web funnel team.
  const textPieces = useMemo(() => {
    return text.split(/{start_bold}|{end_bold}/);
  }, [text]);

  return (
    <div>
      {textPieces.map((piece, index) => {
        const isItBoldPiece = index % 2 === 1;
        return isItBoldPiece ? <strong>{piece}</strong> : piece;
      })}
    </div>
  );
};

const ModalLoading: FC = () => {
  const i18n = useI18n();

  return (
    <Stack direction="row" alignItems="center" gap={2}>
      <Spinner size="small" />
      <Text variant="body_2">{i18n.trans(t("flights_sr_genai_search_summary_loading"))}</Text>
    </Stack>
  );
};

const ModalError: FC = () => {
  const i18n = useI18n();

  return <Text variant="body_2">{i18n.trans(t("flights_sr_genai_modal_search_summary_loading_error"))}</Text>;
};

export default GenAiSearchSummary;
