import React, { FC } from "react";

import { useSelector } from "react-redux";

import { getFetchStatus, getSearchAiSummaryText } from "store/searchAiSummary/selectors";
import GenAiSearchSummary from "components/elements/GenAiSearchSummary";

import useAiSummaryController from "./useAiSummary.controller";
import styles from "./AiSummary.module.css";
import flights_web_sr_gen_ai_summary_desktop from "utils/experiments/funnel/flights_web_sr_gen_ai_summary_desktop";
import { SESSION_STORAGE_KEY_SEARCH_AI_SUMMARY_DISMISSED } from "../../../../constants";
import { flightsSessionStore } from "@flights/web-api-utils-universal";

const AiSummary: FC = () => {
  useAiSummaryController();
  const fetchStatus = useSelector(getFetchStatus);
  const summaryText = useSelector(getSearchAiSummaryText);

  const isDismissed = Boolean(flightsSessionStore.get(SESSION_STORAGE_KEY_SEARCH_AI_SUMMARY_DISMISSED));
  if (isDismissed) {
    return null;
  }

  return (
    <GenAiSearchSummary
      className={styles.root}
      text={summaryText}
      isLoading={fetchStatus === "loading"}
      isError={fetchStatus === "failed"}
      onBannerCtaClick={() => {
        flights_web_sr_gen_ai_summary_desktop.goals.bannerCtaClick();
        fetchStatus === "loading" && flights_web_sr_gen_ai_summary_desktop.goals.loading();
        fetchStatus === "success" && flights_web_sr_gen_ai_summary_desktop.goals.success();
        fetchStatus === "failed" && flights_web_sr_gen_ai_summary_desktop.goals.error();
      }}
      onBannerClose={() => {
        flightsSessionStore.set(SESSION_STORAGE_KEY_SEARCH_AI_SUMMARY_DISMISSED, true);
        flights_web_sr_gen_ai_summary_desktop.goals.bannerClose();
      }}
    />
  );
};

export default AiSummary;
