/* eslint-disable no-restricted-syntax -- this line was auto generated, hence fix the issue timely */
import React, { useMemo } from "react";
import { Banner, Link, Text, Icon } from "@bookingcom/bui-react";
import { useI18n, t } from "@bookingcom/lingojs-react";
import { CreditCardBackIcon, PopoutIcon } from "@bookingcom/bui-assets-react/streamline/index.js";
import useUserAgent from "hooks/useUserAgent";
import useRouteName from "hooks/useRouteName";
import { INSTALLMENTS_FAQ_URL } from "@flights/constants";
import useLocaleContext from "hooks/useLocaleContext";
import useIsBR from "hooks/useIsBR";
import styles from "./InstalmentsBanner.module.css";

const InstalmentsBanner = () => {
  const { isMobile } = useUserAgent();
  const { locale } = useLocaleContext();
  const routeName = useRouteName();
  const isBR = useIsBR();
  const i18n = useI18n();

  const shouldShow = useMemo(() => {
    return (
      isBR &&
      [
        "checkout-fare",
        "checkout-seat-selection",
        "checkout-sirf",
        "checkout-ticket-type",
        "checkout",
        "checkout2",
        "flightdetails",
        "searchresults"
      ].some((r) => r === routeName)
    );
  }, [routeName, isBR]);

  if (!shouldShow) return null;

  return isMobile ? (
    <a
      href={`${INSTALLMENTS_FAQ_URL}?lang=${locale.code}`}
      target="_blank"
      rel="noreferrer"
      className={`${styles.wrapper} ${styles.link}`}
      title={i18n.trans(t("flights_price_breakdown_sr_instalment_cta"))}
    >
      <div className={styles.body}>
        <Text color="neutral" variant="body_2">
          {i18n.trans(t("flights_price_breakdown_instalment_message"))}
        </Text>
      </div>
      <div className={styles.icon}>
        <Icon color="action" svg={PopoutIcon} size="medium" />
      </div>
    </a>
  ) : (
    <Banner dismissible={false} startIcon={CreditCardBackIcon}>
      <Text variant="body_2">{i18n.trans(t("flights_price_breakdown_sr_instalment_message"))}</Text>
      <Link
        href={`${INSTALLMENTS_FAQ_URL}?lang=${locale.code}`}
        variant="primary"
        className={styles.link}
        attributes={{ target: "_blank", rel: "noreferrer" }}
      >
        {i18n.trans(t("flights_price_breakdown_sr_instalment_cta"))}
      </Link>
    </Banner>
  );
};
export default InstalmentsBanner;
