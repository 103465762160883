import React from "react";
import { Box, useTheme, Text, Stack } from "@bookingcom/bui-react";
import { t, useI18n } from "@bookingcom/lingojs-react";
import { SearchboxController, SearchBoxHorizontalDefault } from "@bookingcom/flights-searchbox";

import Container from "../Container";
import NoJS from "screens/NoJS";
import useSearchbox from "hooks/useSearchbox";
import useLocaleContext from "hooks/useLocaleContext";
import useLandingPageEvents from "hooks/c360/useLandingPageEvents";
import { useTrackFlyAnywhere } from "hooks/useTrackFlyAnywhere";

import styles from "./HomeSearchHeader.desktop.module.css";
import { BLACK_FRIDAY_BG_COLOR } from "../../../constants";

const offset = 54; // Half the searcharea height
const offsetAddition = 14;

const HomeSearchHeader = () => {
  const theme = useTheme();
  const i18n = useI18n();
  const searchBoxProps = useSearchbox();
  const { isRTL } = useLocaleContext();
  const trackLandingPageEvents = useLandingPageEvents();
  const { trackFlyAnywhereWww } = useTrackFlyAnywhere();

  return (
    <>
      <Box
        className={styles.box}
        attributes={{
          style: {
            backgroundColor: `${BLACK_FRIDAY_BG_COLOR}`,
            padding: 0
          }
        }}
      >
        <Container className={styles.container}>
          <NoJS />
          <Stack
            gap={1}
            direction="row"
            justifyContent="start"
            attributes={{ style: { paddingBottom: offset + offsetAddition + 5 } }}
          >
            <Stack.Item grow attributes={{ style: { maxWidth: "600px" } }}>
              <Text variant="display_3" data-testid="home_header" tagName="h1" color="white" className={styles.title}>
                {i18n.trans(t("brand_indexbanners_flights_winterdeal_jan_2025_herobanner_desktop_title"))}
              </Text>
              <Text variant="featured_2" color="white" className={styles.subtitle}>
                {i18n.trans(t("brand_indexbanners_flights_winterdeal_jan_2025_herobanner_desktop_subtitle"))}
              </Text>
            </Stack.Item>
          </Stack>
        </Container>
      </Box>

      <Box
        attributes={{
          style: {
            width: "100%",
            paddingTop: 0,
            paddingBottom: theme.units.spacing_8x
          }
        }}
      >
        <Container
          style={{
            padding: 0,
            transform: `translateY(calc(-${offset + offsetAddition}px - ${theme.units.spacing_3x}))`,
            marginBottom: -offset
          }}
        >
          <SearchboxController
            i18n={i18n}
            {...searchBoxProps}
            hideShadow={true}
            isRTL={isRTL}
            isHeaderUpdateExp={true}
            trackFlyAnywhereWww={trackFlyAnywhereWww}
            onSearch={(searchParams) => {
              trackLandingPageEvents("click", "searchbox");
              searchBoxProps.onSearch(searchParams);
            }}
          >
            {SearchBoxHorizontalDefault}
          </SearchboxController>
        </Container>
      </Box>
    </>
  );
};

export default HomeSearchHeader;
