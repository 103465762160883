import { UIClientFetchError, UIEsim, UIFetchStatus } from "@flights/types";

interface EsimState {
  index: {
    fetchStatus: UIFetchStatus;
    error?: UIClientFetchError;
    esim?: UIEsim;
  };
  search: {
    fetchStatus: UIFetchStatus;
    error?: UIClientFetchError;
    esim?: UIEsim;
  };
  flight: {
    fetchStatus: UIFetchStatus;
    error?: UIClientFetchError;
    esim?: UIEsim;
  };
  preOrder: {
    fetchStatus: UIFetchStatus;
    error?: UIClientFetchError;
    esim?: UIEsim;
  };
  order: {
    fetchStatus: UIFetchStatus;
    error?: UIClientFetchError;
    esim?: UIEsim;
  };
}

export const getInitialState = (): EsimState => ({
  index: {
    fetchStatus: "initial",
    esim: {},
    error: undefined
  },
  search: {
    fetchStatus: "initial",
    esim: {},
    error: undefined
  },
  flight: {
    fetchStatus: "initial",
    esim: {},
    error: undefined
  },
  preOrder: {
    fetchStatus: "initial",
    esim: {},
    error: undefined
  },
  order: {
    fetchStatus: "initial",
    esim: {},
    error: undefined
  }
});

export type State = EsimState;
