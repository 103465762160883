import { useCallback } from "react";
import useRouteName from "../hooks/useRouteName";
import { clientFetch } from "@flights/client-fetch";
import reportError from "../utils/report-error";
import { UIClientMetricName, UIClientMetric } from "@flights/types";
import Debug from "debug";

const debug = Debug("useClientMetrics");

type UseClientMetricsResult = {
  trackClientMetric: (metricName: UIClientMetricName, metricValue?: number) => void;
  trackRenderTime: (id: string, phase: "mount" | "update", actualDuration: number) => void;
};

const useClientMetrics = (): UseClientMetricsResult => {
  const route = useRouteName();

  const track = useCallback(
    (name: UIClientMetricName, value: number) => {
      buffer.push({ name, route, value });
    },
    [route]
  );

  const onRender = useCallback(
    (id: string, phase: string, actualDuration: number) => {
      const name = `${id}_${phase}` as UIClientMetricName;
      phase === "mount" && track(name, actualDuration);
    },
    [track]
  );

  return {
    trackClientMetric: track,
    trackRenderTime: onRender
  };
};

const buffer: UIClientMetric[] = [];

const REPORT_INTERVAL = 10 * 1000; // ms

const initClientMetrics = () => {
  setInterval(reportClientMetrics, REPORT_INTERVAL);
};

const reportClientMetrics = () => {
  if (buffer.length > 0) {
    const metricsToReport = buffer.splice(0); // also empties `buffer`

    debug("Reported metrics", metricsToReport);

    // Webview users are detected as abnormal visitors (as far as ET concerns), thus shouldNotTrack is True.
    // However, we would like to track the usage of Webview platform.
    const canTrack = !window?.__GLOBAL_CONTEXT__?.shouldNotTrack || window?.__GLOBAL_CONTEXT__?.userAgent.isWebview;
    if (!canTrack) {
      return;
    }
    clientFetch("/track/client-metrics", {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      credentials: "include",
      referrerPolicy: "strict-origin-when-cross-origin",
      body: JSON.stringify({
        metrics: metricsToReport
      })
    }).catch((error) => {
      debug("Failed to report", metricsToReport, "with error", error);
      buffer.unshift(...metricsToReport); // allowing failed metrics to be sent in the next round
      if (error.name !== "ClientFetchNotAuthorizedError") {
        reportError(new Error(`Failed to report client metrics from client side. ${error.message}`));
      }
    });
  }
};

export default useClientMetrics;
export { initClientMetrics };
