import { ActionsUnion, createAction } from "@bookingcom/flights-core";
import { UIClientFetchError, UIEsim } from "@flights/types";

export enum ActionTypes {
  fetchIndex = "esim/index/fetch",
  fetchIndexSuccess = "esim/index/fetchSuccess",
  fetchIndexError = "esim/index/fetchError",

  fetchSearch = "esim/search/fetch",
  fetchSearchSuccess = "esim/search/fetchSuccess",
  fetchSearchError = "esim/search/fetchError",

  fetchFlight = "esim/flight/fetch",
  fetchFlightSuccess = "esim/flight/fetchSuccess",
  fetchFlightError = "esim/flight/fetchError",

  fetchPreOrder = "esim/preOrder/fetch",
  fetchPreOrderSuccess = "esim/preOrder/fetchSuccess",
  fetchPreOrderError = "esim/preOrder/fetchError",

  fetchOrder = "esim/order/fetch",
  fetchOrderSuccess = "esim/order/fetchSuccess",
  fetchOrderError = "esim/order/fetchError"
}

export const actions = {
  fetchIndex: createAction(() => ({
    type: ActionTypes.fetchIndex,
    payload: {
      fetchStatus: "loading"
    }
  })),
  fetchIndexSuccess: createAction((esim: UIEsim) => ({
    type: ActionTypes.fetchIndexSuccess,
    payload: { esim, fetchStatus: "success" }
  })),
  fetchIndexError: createAction((error: UIClientFetchError) => ({
    type: ActionTypes.fetchIndexError,
    payload: { error, fetchStatus: "error" }
  })),

  fetchSearch: createAction(() => ({
    type: ActionTypes.fetchSearch,
    payload: {
      fetchStatus: "loading"
    }
  })),
  fetchSearchSuccess: createAction((esim: UIEsim) => ({
    type: ActionTypes.fetchSearchSuccess,
    payload: { esim, fetchStatus: "success" }
  })),
  fetchSearchError: createAction((error: UIClientFetchError) => ({
    type: ActionTypes.fetchSearchError,
    payload: { error, fetchStatus: "error" }
  })),

  fetchFlight: createAction(() => ({
    type: ActionTypes.fetchFlight,
    payload: { fetchStatus: "loading" }
  })),
  fetchFlightSuccess: createAction((esim: UIEsim) => ({
    type: ActionTypes.fetchFlightSuccess,
    payload: { esim, fetchStatus: "success" }
  })),
  fetchFlightError: createAction((error: UIClientFetchError) => ({
    type: ActionTypes.fetchFlightError,
    payload: { error, fetchStatus: "error" }
  })),

  fetchPreOrder: createAction(() => ({
    type: ActionTypes.fetchPreOrder,
    payload: { fetchStatus: "loading" }
  })),
  fetchPreOrderSuccess: createAction((esim: UIEsim) => ({
    type: ActionTypes.fetchPreOrderSuccess,
    payload: { esim, fetchStatus: "success" }
  })),
  fetchPreOrderError: createAction((error: UIClientFetchError) => ({
    type: ActionTypes.fetchPreOrderError,
    payload: { error, fetchStatus: "error" }
  })),

  fetchOrder: createAction(() => ({
    type: ActionTypes.fetchOrder,
    payload: { fetchStatus: "loading" }
  })),
  fetchOrderSuccess: createAction((esim: UIEsim) => ({
    type: ActionTypes.fetchOrderSuccess,
    payload: { esim, fetchStatus: "success" }
  })),
  fetchOrderError: createAction((error: UIClientFetchError) => ({
    type: ActionTypes.fetchOrderError,
    payload: { error, fetchStatus: "error" }
  }))
};

export type Actions = ActionsUnion<typeof actions>;
