import { useI18n, t } from "@bookingcom/lingojs-react";

export type HiddenFeesControllerProps = {};

export function useHiddenFeesController() {
  const i18n = useI18n();
  return {
    text: i18n.trans(t("flights_price_details_no_hidden_fees"))
  };
}
