import { createMicroConversionTrackingUtils } from "..";

const utils = createMicroConversionTrackingUtils({
  name: "flights_web_reduce_spacing_sr_ddot",
  stages: {
    main: 1, // search results fetched
    direct: 2,
    meta: 3,
    soloTravelers: 4,
    multipleTravelers: 5,
    oneWay: 6,
    roundTrip: 7
  },
  defaultStage: 1,
  mainStage: 1,
  goals: {},
  goalsWithValue: []
});

export default utils;
