import { checkAppVersion } from "@flights/web-api-utils-universal";
import { clientPayload as etClientPayload } from "@flights/et-universal";
import { getMarketingTrackingVariable } from "@flights/web-api-utils-universal";
import { X_BOOKING_FLIGHTS_DEV_SERVICE_OVERRIDE, X_BOOKING_FLIGHTS_CLIENT_HINTS } from "@flights/constants";
import { devOverrideUtils } from "@flights/web-api-utils-universal";
export class ClientFetchError extends Error {
    constructor(message) {
        super(message);
        this.name = "ClientFetchError";
    }
}
export class ClientFetchNotAuthorizedError extends ClientFetchError {
    constructor(message) {
        super(message);
        this.name = "ClientFetchNotAuthorizedError";
    }
}
const overrideParamsRegexList = [
    /^b_feature_/,
    /my_ip/,
    /i_am_from/,
    /debug/,
    /show_tags/,
    /mock-scenario-enabled/,
    /mock-scenario-name/
];
function appendOverrideParams(url = "", isInternal = false) {
    if (!isInternal)
        return url;
    const [urlPath, urlSearch = ""] = url.split("?");
    const urlSearchParam = new URLSearchParams(urlSearch);
    new URLSearchParams(window?.location?.search || "").forEach((value, param) => {
        if (overrideParamsRegexList.some((rx) => rx.test(param))) {
            urlSearchParam.set(param, value);
        }
    });
    return `${urlPath}?${urlSearchParam.toString()}`;
}
export function clientFetch(...args) {
    const { debugAllExtraProducts, marketingTrackingVariables: trackingVariables, emkToken, isInternal, env, soylentEmail, tracingHeaders } = window.__GLOBAL_CONTEXT__;
    const [url, options = {}] = args;
    let { headers = {} } = options;
    headers = { ...headers, ...tracingHeaders };
    // @ts-expect-error: Element implicitly has an 'any' type. Fix the issue timely.
    headers["X-Requested-From"] = "clientFetch";
    const aid = getMarketingTrackingVariable("aid") || trackingVariables?.aid;
    const label = getMarketingTrackingVariable("label") || trackingVariables?.label;
    const etgTestUrl = sessionStorage && sessionStorage.getItem("debugEtgUrl");
    const debugAllTravelerDataRequirements = sessionStorage && sessionStorage.getItem("debugAllTravelerDataRequirements");
    if (aid) {
        // @ts-expect-error: Element implicitly has an 'any' type. Fix the issue timely.
        headers["X-Booking-Affiliate-Id"] = aid;
    }
    if (label) {
        // @ts-expect-error: Element implicitly has an 'any' type. Fix the issue timely.
        headers["X-Booking-Label"] = label;
    }
    if (emkToken) {
        // @ts-expect-error: Element implicitly has an 'any' type. Fix the issue timely.
        headers["X-Booking-EMK-Token"] = emkToken;
    }
    if (debugAllExtraProducts) {
        // @ts-expect-error: Element implicitly has an 'any' type. Fix the issue timely.
        headers["X-Flights-Debug-All-Products"] = "1";
    }
    if (soylentEmail) {
        // @ts-expect-error: Element implicitly has an 'any' type. Fix the issue timely.
        headers["X-Soylent-Email"] = soylentEmail;
    }
    if (etgTestUrl) {
        // @ts-expect-error: Element implicitly has an 'any' type. Fix the issue timely.
        headers["X-Flights-Debug-Etg-Test-Url"] = etgTestUrl;
    }
    if (debugAllTravelerDataRequirements) {
        // @ts-expect-error: Element implicitly has an 'any' type. Fix the issue timely.
        headers["X-Flights-Debug-All-Traveler-Data-Requirements"] = "1";
    }
    // Set the supported Flights Client Hints by the Web app.
    const hints = ["price_change_v2"];
    // @ts-expect-error: Element implicitly has an 'any' type. Fix the issue timely.
    headers[X_BOOKING_FLIGHTS_CLIENT_HINTS] = hints.join(",");
    if (env !== "prod") {
        const devOverrides = devOverrideUtils.getAll();
        if (typeof devOverrides == "object" && Object.keys(devOverrides).length) {
            // @ts-expect-error: Element implicitly has an 'any' type. Fix the issue timely.
            headers[X_BOOKING_FLIGHTS_DEV_SERVICE_OVERRIDE] = JSON.stringify(devOverrides);
        }
    }
    if (!options.credentials) {
        options.credentials = "same-origin";
    }
    let apiPrefix = "";
    if (String(url).startsWith("/")) {
        apiPrefix = window.__GLOBAL_CONTEXT__?.apiPrefix;
    }
    const fetchUrl = appendOverrideParams(apiPrefix + url, isInternal);
    return fetch(fetchUrl, { ...options, headers })
        .then((res) => {
        etClientPayload.updateClientSideContext(res.headers.get("X-Booking-Experiment-Soylent-Payload"));
        checkAppVersion.checkForAppVersionMismatch(res.headers.get("X-Booking-App-Version"));
        if (res.ok) {
            if (res.headers.get("Content-Type") === "application/pdf") {
                return res.blob();
            }
            return res.json();
        }
        else {
            if (res.status === 403) {
                throw new ClientFetchNotAuthorizedError("Fetch: Not Authorized");
            }
            else {
                throw new ClientFetchError("Fetch: Non-OK response");
            }
        }
    })
        .then((data) => {
        etClientPayload.checkForNewClientsidePayload(data?._meta?._clientsidePayload);
        if (data.error) {
            // UIError
            // http status was ok, but response data has error. Throw!
            throw data.error;
        }
        return data;
    });
}
