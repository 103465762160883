import React, { useMemo } from "react";
import { Stack } from "@bookingcom/bui-react";
import { Breakdown } from "./Breakdown";
import useProfiler from "hooks/useProfiler";
import isHungaryDepartingRyanAirFlight from "utils/hungaryTaxes";
import { Installments, HiddenFees, Atol } from "./banners";
import { UIFlightData } from "@flights/types";

export type UnifiedPriceBreakdownCheckoutProps = {
  flight: UIFlightData;
};

export function UnifiedPriceBreakdownCheckout(props: UnifiedPriceBreakdownCheckoutProps) {
  const { flight } = props;
  const { isSolo, isWithInfants, isWithKids } = useProfiler();
  const segments = useMemo(() => flight?.segments, [flight]);
  const isHungaryDepartingRyanAir = useMemo(() => isHungaryDepartingRyanAirFlight(segments), [segments]);
  const unifiedPriceBreakdown = useMemo(() => flight?.unifiedPriceBreakdown, [flight]);
  const isOneAdultTraveller = useMemo(
    () => isSolo() && !isWithInfants() && !isWithKids(),
    [isSolo, isWithInfants, isWithKids]
  );

  if (!unifiedPriceBreakdown) return null;

  return (
    <Stack gap={4}>
      <Breakdown
        {...unifiedPriceBreakdown}
        isHungaryDepartingRyanAir={isHungaryDepartingRyanAir}
        isOneAdultTraveller={isOneAdultTraveller}
      />
      <Atol />
      <HiddenFees />
      <Installments />
    </Stack>
  );
}
