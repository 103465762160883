import React from "react";
import { Provider as ReduxProvider } from "react-redux";
// These polyfill entry points will be replaced by core-js' target features through Babel
import "core-js/stable";
import { hydrateRoot } from "react-dom/client";
import { IFetchLike } from "@bookingcom/remote-component/src/types/utils";
import { HelmetProvider } from "react-helmet-async";
import { loadableReady } from "@loadable/component";
import { BrowserRouter } from "react-router-dom";
import "regenerator-runtime/runtime";

import { type ArgumentsInterface, I18n } from "@bookingcom/lingojs-core";
import { I18nProvider } from "@bookingcom/lingojs-react";

import App from "./app/App";
import GlobalProvider from "./app/GlobalContext/GlobalProvider";
import LocaleProvider from "./app/locale/LocaleProvider";
import { TrackerProvider } from "./app/TrackerContext/TrackerProvider";
import useGlobalContext from "./hooks/useGlobalContext";
import { localizationUtils } from "@flights/web-api-utils-universal";
import { trackExperiment, trackExperimentStage } from "./utils/et";
import { MultiProductWrapper } from "components/elements/MultiProducts/MultiProductWrapper";
import OrchestratorProvider from "./app/OrchestratorContext/OrchestratorProvider";
import "./utils/client-orchestrator";
import { initRemoteComponentContext, SupportedProducts } from "@bookingcom/remote-component";
import RemoteComponentExperiment from "app/RemoteCompoent/RemoteComponentExperiment";
import { reportError } from "@bookingcom/flights-core";
import { REMOTE_COMPONENT_PROXY_ENDPOINT } from "./constants";
import { createStore } from "store";
import { appendFeatureAndExperimentParams } from "utils/fetchParams";
import { isFeatureRunningClientSide } from "utils/features";
import { matchPath } from "react-router-dom";
import routeMap from "app/routes";
import { clientFetch } from "@flights/client-fetch";
import { UIFlogEvent } from "utils/flog";
import { getCopyExperimentParamsClinet } from "utils/onTranslateUtils";
import { CopyExperimentContext } from "@bookingcom/lingojs-experiments";
import { getMessagesFunctionRuntime } from "@bookingcom/lingojs-compiler";
import useUserAgent from "hooks/useUserAgent";

const i18nState = window.__I18N_STATE__;
if (!i18nState?.locale) throw new Error("localeID not present on window");

const dateLocaleLoader = () =>
  import(`date-fns/locale/${localizationUtils.OurLocalesToDateFNSLocaleMapping[i18nState.locale]}`);
const el = document.getElementById("root");

const prefix = process.env.RAZZLE_PREFIX || "";
const globalContext = window.__GLOBAL_CONTEXT__;

try {
  if (window.sessionStorage.debugAllExtraProducts) {
    globalContext.debugAllExtraProducts = true;
  }
} catch (e) {} // eslint-disable-line flights/no-empty-catch

function I18nProviderRenderer(props: { children: React.ReactNode }) {
  const { showCopyTags: showTags, env, ipCountry } = useGlobalContext();
  const { isMobile } = useUserAgent();

  // i18n with experiments =====================================================
  if (!i18nState) throw new Error("localeID not present on window");
  const { locale, plural, clientState, dictionary, messages } = i18nState;
  const expMessages = getMessagesFunctionRuntime(dictionary);
  const copyExperimentParams = getCopyExperimentParamsClinet(ipCountry, isMobile);
  const context = CopyExperimentContext.fromClientSidePayloadJSON(clientState);
  const i18n = new I18n(locale, messages, plural);
  i18n.addMessages(expMessages);
  i18n.onTranslate((tag: string, variables?: ArgumentsInterface) => {
    const data = context.getVariants(locale, tag, variables, copyExperimentParams);
    if (!data || !data.matchFound) return tag;
    if (!data.experimentTagName) return tag;
    const variant = trackExperiment(data.experimentTagName) || 0;
    const copyVariant = data.variants?.[variant - 1];
    // Mutating the object reference is a bad practice but "onTranslate" does
    // not allow changing the item atm if not for this we won't be able to run
    // an experiment where one list item replaces another
    // @ts-ignore
    if (!!copyVariant?.item && !!vars) vars["item"] = copyVariant.item;
    trackExperimentStage(data.experimentTagName, 1);
    return copyVariant?.tag || tag;
  });
  i18n.setShowTags(!!showTags);
  if (env === "prod") i18n.setFallbackRenderer(() => "");
  return <I18nProvider store={i18n}>{props.children}</I18nProvider>;
}

/**
 * @TODO: to be scaled to permanent goals for all routes
 * @link: https://jira.booking.com/jira/browse/META-1969
 */
function handleClientRenderingError(error: Error) {
  const route = routeMap({
    isMobile: globalContext?.userAgent?.isMobile,
    isPaymentCollectionPage: !!isFeatureRunningClientSide("FLIGHTS_WEB_PAYMENT_COLLECTION_NEW_PAGE")
  }).find((route) => matchPath(window.location.pathname, { path: route.path, exact: true }));

  if (route?.name === "home") {
    if (isFeatureRunningClientSide("FLIGHTS_WEB_CAT_REPORT_RENDERING_ERRORS_HOME")) {
      const errorWithData = {
        ...error,
        title: "Home page unhandled error on client",
        isMobile: globalContext.userAgent.isMobile,
        isTablet: globalContext.userAgent.isTablet,
        isWebview: globalContext.userAgent.isWebview,
        isInternal: globalContext.isInternal,
        isBot: globalContext.userAgent.isBot,
        isKnownBot: globalContext.isKnownBot
      };

      reportError(errorWithData, "home");
    }
  }
}

function onHydrationError(error: Error) {
  if (!isFeatureRunningClientSide("FLIGHTS_WEB_CAT_TRACK_HYDRATION_ERRORS")) return;

  const route = routeMap({
    isMobile: globalContext?.userAgent?.isMobile,
    isPaymentCollectionPage: !!isFeatureRunningClientSide("FLIGHTS_WEB_PAYMENT_COLLECTION_NEW_PAGE")
  }).find((route) => matchPath(window.location.pathname, { path: route.path, exact: true }));

  const errorWithData = {
    ...error,
    route: route?.name,
    isMobile: globalContext.userAgent.isMobile,
    isTablet: globalContext.userAgent.isTablet,
    isWebview: globalContext.userAgent.isWebview,
    isInternal: globalContext.isInternal,
    isBot: globalContext.userAgent.isBot,
    isKnownBot: globalContext.isKnownBot
  };

  // extracted from hooks/useEvents.tsx#L36
  void clientFetch("/events/grumble", {
    method: "post",
    body: JSON.stringify({
      message: "Home page hydration error on client",
      payload: errorWithData
    } as UIFlogEvent),
    headers: {
      "Content-Type": "application/json",
      "X-Booking-Referrer": window.location.href || ""
    }
  });
}

function main({ rootNode }: { rootNode: typeof el }) {
  loadableReady()
    .then(async () => {
      if (!rootNode) {
        throw new Error("No root node provided");
      }
      let dateLocale;
      if (dateLocaleLoader) {
        dateLocale = await dateLocaleLoader();

        if (!dateLocale.default) {
          throw new Error("Locales should either be imported from date-fns or follow its pattern");
        }

        dateLocale = dateLocale.default;
      }
      const language = globalContext.lang;

      const orcaBaseUrl = globalContext.apiPrefix
        ? globalContext.apiPrefix
        : `${window.location.protocol}//${window.location.host}`;
      const boundedFetch = window.fetch.bind(window);

      const rcContextFetch: IFetchLike = (input, requestInitOptions) => {
        const fetchParams = { ...requestInitOptions, credentials: "include" };
        return boundedFetch(input, fetchParams);
      };

      const orcaURLPathName = globalContext.features.FLIGHTS_WEB_RC_ENABLE_DEBUG_PARAMS
        ? appendFeatureAndExperimentParams(REMOTE_COMPONENT_PROXY_ENDPOINT)
        : REMOTE_COMPONENT_PROXY_ENDPOINT;

      const rcContextLogger = { logError: reportError, logWarning: reportError };
      const rcContextOptions = {
        orcaUrl: orcaBaseUrl.concat(orcaURLPathName),
        product: SupportedProducts.Flights,
        translationRequestContext: {} //context will be generated on nodejs
      };
      const elementWithNonce = document.querySelector("script[nonce]");
      let cspNonce;
      if (elementWithNonce) {
        // @ts-expect-error: Element implicitly has an 'any' type. Fix the issue timely.
        cspNonce = elementWithNonce["nonce"] || elementWithNonce.getAttribute("nonce");
      }

      const rcContext = await initRemoteComponentContext(rcContextFetch, rcContextOptions, rcContextLogger, cspNonce);
      const store = createStore();

      hydrateRoot(
        rootNode,
        <BrowserRouter basename={`/${prefix}`}>
          <LocaleProvider locale={dateLocale} isRTL={language ? localizationUtils.isRTL(language) : false}>
            <GlobalProvider {...globalContext}>
              <TrackerProvider>
                <I18nProviderRenderer>
                  <ReduxProvider store={store} noopCheck="never">
                    <HelmetProvider>
                      <OrchestratorProvider>
                        <MultiProductWrapper>
                          <RemoteComponentExperiment value={rcContext}>
                            <App />
                          </RemoteComponentExperiment>
                        </MultiProductWrapper>
                      </OrchestratorProvider>
                    </HelmetProvider>
                  </ReduxProvider>
                </I18nProviderRenderer>
              </TrackerProvider>
            </GlobalProvider>
          </LocaleProvider>
        </BrowserRouter>,
        {
          onRecoverableError: onHydrationError
        }
      );
    })
    .catch((e: Error) => {
      handleClientRenderingError(e);
      /*eslint-disable-next-line flights/no-unassigned-todo-comments*/
      throw e; // TODO: client should render an error state on hydration
    });
}

if (module.hot) {
  module.hot.accept();
}

main({ rootNode: el });

export { main as default };
