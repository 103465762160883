import { useCallback, useMemo } from "react";
import useRouteName from "./useRouteName";
import useProfiler from "./useProfiler";
import { trackExperimentStage } from "utils/et";
import { UIFlightData } from "@flights/types";
import useVirutalInterlining from "./useVirutalInterlining";
// eslint-disable-next-line import/no-deprecated
import { useStore } from "store";

export default function useTrackViAA() {
  const routeName = useRouteName();
  const { isDirect } = useProfiler();
  const { isVirtualInterliningFlight } = useVirutalInterlining();
  // eslint-disable-next-line import/no-deprecated
  const { flightDetails } = useStore();
  const experiment = "flights_web_aa_virtual_interlining_tracking";
  const flight = flightDetails?.flight;

  const isCabinIncluded = useMemo(
    () => !!flight?.includedProducts?.segments?.some((p) => p.some((_) => _.luggageType === "HAND")),
    [flight]
  );

  const isCabinAvailable = useMemo(
    () => flight?.ancillaries?.cabinBaggagePerTraveller?.luggageAllowance?.luggageType === "HAND",
    [flight]
  );

  // All users on SR
  const ViAAInStage1 = useCallback(() => {
    if (!isDirect()) return;
    if (routeName !== "searchresults") return;
    trackExperimentStage(experiment, 1);
  }, [isDirect, routeName]);

  // Users who saw VI flights on SR in the viewport
  const ViAAInStage2 = useCallback(
    (_f?: UIFlightData) => {
      if (!isDirect() || !_f) return;
      if (!isVirtualInterliningFlight(_f?.segments || [])) return;
      trackExperimentStage(experiment, 2);
    },
    [isDirect, isVirtualInterliningFlight]
  );

  // Users who opened FD for VI flights
  const ViAAInStage3 = useCallback(
    (_f?: UIFlightData) => {
      if (!isDirect() || !_f) return;
      if (!isVirtualInterliningFlight(_f?.segments || [])) return;
      trackExperimentStage(experiment, 3);
    },
    [isDirect, isVirtualInterliningFlight]
  );

  // Users who opened FD for non VI flights
  const ViAAInStage4 = useCallback(
    (_f?: UIFlightData) => {
      if (!isDirect() || !_f) return;
      if (isVirtualInterliningFlight(_f?.segments || [])) return;
      trackExperimentStage(experiment, 4);
    },
    [isDirect, isVirtualInterliningFlight]
  );

  // All users on Ancillaries screen for VI flights
  const ViAAInStage5 = useCallback(() => {
    if (!isDirect() || !flight) return;
    if (routeName !== "checkout2") return;
    if (!isVirtualInterliningFlight(flight?.segments || [])) return;
    trackExperimentStage(experiment, 5);
  }, [isDirect, isVirtualInterliningFlight, routeName, flight]);

  // All users on Ancillaries screen for non VI flights
  const ViAAInStage6 = useCallback(() => {
    if (!isDirect() || !flight) return;
    if (routeName !== "checkout2") return;
    if (isVirtualInterliningFlight(flight?.segments || [])) return;
    trackExperimentStage(experiment, 6);
  }, [isDirect, isVirtualInterliningFlight, routeName, flight]);

  // All users on Ancillaries screen for VI flights where cabin bags are not included and not available as extra bag
  const ViAAInStage7 = useCallback(() => {
    if (!isDirect() || !flight) return;
    if (routeName !== "checkout2") return;
    if (!isVirtualInterliningFlight(flight?.segments || [])) return;
    if (isCabinIncluded || isCabinAvailable) return;
    trackExperimentStage(experiment, 7);
  }, [isDirect, isVirtualInterliningFlight, routeName, flight, isCabinIncluded, isCabinAvailable]);

  return {
    ViAAInStage1,
    ViAAInStage2,
    ViAAInStage3,
    ViAAInStage4,
    ViAAInStage5,
    ViAAInStage6,
    ViAAInStage7
  };
}
