import { createTrackingUtils } from "../index";
import { isOfMetaOrigin } from "utils/marketing-url-params";

const utils = createTrackingUtils({
  name: "flights_web_sr_gen_ai_summary_desktop",
  stages: {
    direct: 2,
    meta: 3,
    solo: 4,
    nonSolo: 5,
    children: 6
  },
  goals: {
    bannerCtaClick: 1,
    loading: 2,
    success: 3,
    error: 4,
    bannerClose: 5
  },
  defaultStage: 1
});

const trackStages = (adults: number, children: number) => {
  isOfMetaOrigin() ? utils.stages.meta() : utils.stages.direct();

  if (adults === 1) {
    utils.stages.solo();
  } else if (children === 0) {
    utils.stages.nonSolo();
  } else {
    utils.stages.children();
  }
};

export default { ...utils, trackStages };
