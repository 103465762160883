/* eslint-disable no-restricted-syntax -- this line was auto generated, hence fix the issue timely */
import React, { Profiler, useContext, useEffect, useRef } from "react";
import { Stack, Text, Title, useTheme } from "@bookingcom/bui-react";
import { SearchboxController, SearchBoxHorizontalDefault } from "@bookingcom/flights-searchbox";
import { t } from "@bookingcom/lingojs-core";
import { useI18n } from "@bookingcom/lingojs-react";
import CheapFlights from "components/elements/CheapFlights";
import FrequentlyAskedQuestionsDesktop from "components/elements/FrequentlyAskedQuestions/FrequentlyAskedQuestions.desktop";
import TrendingDestinations from "components/elements/TrendingDestinations";
import UspBlock from "components/elements/UspBlock";
import { trackMetaLandingsCustomGoalsV2 } from "utils/experiments/track-meta-landings-v2";
import { SearchDesktop } from "app/routes";
import { AppShellDesktop } from "../components/elements/AppShell";
import Container from "../components/elements/Container";
import useClientMetrics from "../hooks/useClientMetrics";
import useGlobalContext from "../hooks/useGlobalContext";
import useLocaleContext from "../hooks/useLocaleContext";
import useSearchbox from "../hooks/useSearchbox";
import useTrackPageLoad from "../hooks/useTrackPageLoad";
import { trackExperiment, trackExperimentStage, trackGoal } from "utils/et";
import { PageHeader } from "./Home";
import NoJS from "./NoJS";
import StaticContext from "app/StaticContext/StaticContext";
import HomeRecentSearch from "components/elements/HomeRecentSearch";
import Frame from "components/elements/Frame";
import { HomeSearchHeaderDesktop } from "components/elements/HomeSearchHeader";
import {
  RemoteAppleCardIndex,
  RemoteGeniusLaunchSheet,
  RemoteGeniusLoyalty,
  RemoteRewardsLandSheet
} from "components/remote";
import { IndexCampaignBanner } from "components/elements/IndexCampaignBanner";
import ThemeBasedDestinations from "components/elements/ThemeBasedDestinations";
import NoSSR from "../app/NoSSR";
import useLandingPageEvents from "hooks/c360/useLandingPageEvents";
import { GeniusAirlinesBanner } from "components/elements/GeniusAirlinesBanner";
import useProfiler from "hooks/useProfiler";
import { useTrackFlyAnywhere } from "hooks/useTrackFlyAnywhere";
import CrowdstrikeOutage from "components/elements/CrowdstrikeOutage";
import { useTrackPpcWithoutSearchHistory } from "utils/experiments/customer-aquisition/flights_web_cat_ppc_without_search_history_aa";
import { GeniusFlightsHomeBanner } from "components/elements/FlightCampaignTCBanner/GeniusHomeBanner";
import { stage, trackWithDefaultStage } from "@flights/et-universal";
import { FlightsInterlinkingDesktop } from "components/elements/FlightsInterlinking";
import flights_web_cat_theme_based_destinations_www from "utils/experiments/customer-aquisition/flights_web_cat_theme_based_destinations_www";
import useIsInViewport from "hooks/useIsInViewport";
import EsimBanner from "ancillaries/components/Esim/EsimBanner";
import { useSelector } from "react-redux";
import { getEsimEligibilityIndex, getFetchStatusIndex } from "store/esim/selectors";
import flights_ace_web_esim_banner from "utils/experiments/ace/flights_ace_web_esim_banner";
import { useEsimIndex } from "ancillaries/hooks/Esim/useEsimFetch";
import Wrapper from "components/atoms/Wrapper";
import { getUserInfo } from "store/user/selectors";
import WeekendGetaways from "components/elements/WeekendGetaways";
import { EXP_FLIGHTS_IS_TRAVELLING_FOR_WORK } from "constants/experiments";
import { useLatestSearch } from "hooks/useLatestSearch";
import { WINTER_DEALS_EXLUDED_COUNTRIES } from "../constants";
import { isOfMetaOrigin, isOfPPCOrigin } from "utils/marketing-url-params";

const HomeDesktop = () => {
  const staticContext = useContext(StaticContext);
  const theme = useTheme();
  const i18n = useI18n();
  const trackContextEventV2 = useTrackPageLoad("index");
  const { userAgent, searchHistory, features, ipCountry = "", lang } = useGlobalContext();
  const searchBoxProps = useSearchbox();
  const { isRTL } = useLocaleContext();
  const { trackRenderTime } = useClientMetrics();
  const trackLandingPageEvents = useLandingPageEvents();
  const { isMeta } = useProfiler();
  const { trackFlyAnywhereWww } = useTrackFlyAnywhere();

  const isEsimEligible = !!useSelector(getEsimEligibilityIndex);
  const showEsimBanner = isEsimEligible && !!flights_ace_web_esim_banner.trackWithDefaultStage();
  const fetchEsim = useEsimIndex();
  const esimFetchStatus = useSelector(getFetchStatusIndex);
  const esimBannerRef = useRef<HTMLDivElement>(null);
  const eSimInViewport = useIsInViewport(esimBannerRef.current);
  /** end - flights_ace_web_esim_banner */

  const userInfo = useSelector(getUserInfo);
  const isWinterDealsEligible =
    !WINTER_DEALS_EXLUDED_COUNTRIES.includes(ipCountry) && !isOfMetaOrigin() && !isOfPPCOrigin();

  if (process.env.BUILD_TARGET === "server" && staticContext.set) {
    const { getInitialStateDesktop } = require("../server/screens/home");
    staticContext.set({ getInitialState: getInitialStateDesktop });
  }

  /** start - flights_ace_web_esim_banner */
  useEffect(() => {
    if (esimFetchStatus === "initial") {
      void fetchEsim();
    }
  }, [esimFetchStatus, fetchEsim]);

  useEffect(() => {
    if (showEsimBanner && userInfo) {
      flights_ace_web_esim_banner.stages.with_booking();
    }
  }, [showEsimBanner, userInfo]);

  useEffect(() => {
    if (eSimInViewport && userInfo) {
      flights_ace_web_esim_banner.goals.viewed_esim_banner();
    }
  }, [eSimInViewport, userInfo]);
  /** end - flights_ace_web_esim_banner */

  useEffect(() => {
    SearchDesktop.preload();
    trackContextEventV2();
    trackGoal("flights_home_page_view");
    trackLandingPageEvents("page_view", "homepage");
    stage("flights_web_cat_home_page_traffic_www_aa", 4);

    // track meta users going to index page
    trackMetaLandingsCustomGoalsV2(2, "desktop");
    trackExperimentStage("flights_web_a11y_searchbox", 1); // stage 1 - Desktop + Mdot Index + Search results - a11y searchbox
    trackExperimentStage("flights_web_a11y_searchbox", 2); // stage 2 - Desktop - Index - a11y searchbox
    // bb_flights_prompt_travel_purpose
    trackExperimentStage(EXP_FLIGHTS_IS_TRAVELLING_FOR_WORK, 1);

    flights_web_cat_theme_based_destinations_www.stages.main();

    stage("cm_post_trip_add_rewards_landsheet_to_flights_web", 2);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { canShowOnDesktop } = useTrackPpcWithoutSearchHistory(false);

  useEffect(() => {
    if (!!searchHistory?.length) return;
    trackExperimentStage("flights_web_ddot_sb_bg_color", 1);
    trackExperimentStage("flights_web_ddot_sb_bg_color", 2);
    trackExperimentStage("flights_web_ddot_sb_bg_color", isMeta() ? 5 : 4);
  }, [searchHistory, isMeta]);

  useEffect(() => {
    if (isWinterDealsEligible) {
      stage("flights_web_cat_winter_deals_promo", 2);
    }
  }, [isWinterDealsEligible]);

  /** start: Autofill search box with the latest search parameters */
  useEffect(() => {
    if (!userAgent.isBot && searchHistory?.length > 0) {
      stage("flights_web_cat_autofill_last_search_params_www", 1);

      if (searchHistory[0].lang === lang) {
        stage("flights_web_cat_autofill_last_search_params_www", 2); // main stage
      } else {
        stage("flights_web_cat_autofill_last_search_params_www", 3);
      }
    }
  }, [searchHistory, userAgent, lang]);

  const isEligibleForSearchboxAutoFill =
    !userAgent.isBot &&
    searchHistory?.length > 0 &&
    searchHistory[0].lang === lang &&
    !!trackWithDefaultStage("flights_web_cat_autofill_last_search_params_www", 2);
  const searchBoxInitialValue = useLatestSearch(
    searchBoxProps.initialValue,
    searchHistory,
    isEligibleForSearchboxAutoFill
  );
  /** end: Autofill search box with the latest search parameters */

  const hideSBBag = !!searchHistory?.length || !!trackExperiment("flights_web_ddot_sb_bg_color");

  return (
    <Profiler id="screen" onRender={trackRenderTime}>
      <PageHeader />
      <AppShellDesktop>
        <Frame
          alignItems="center"
          direction="column"
          justifyContent="center"
          grow={1}
          attributes={{ style: { width: "100%" } }}
        >
          {isWinterDealsEligible && trackWithDefaultStage("flights_web_cat_winter_deals_promo", 1) ? (
            <HomeSearchHeaderDesktop />
          ) : (
            <Frame
              attributes={{
                style: {
                  backgroundColor:
                    userAgent.isWebview || hideSBBag
                      ? theme.colors.color_background_base
                      : theme.colors.color_background_base_alt,
                  width: "100%"
                }
              }}
            >
              <Container
                style={{
                  margin: `0 auto ${hideSBBag ? theme.units.spacing_12x : theme.units.spacing_16x}`,
                  alignItems: "start"
                }}
              >
                <NoJS />
                <Frame mt={6}>
                  <Stack gap={2}>
                    <Stack gap={2}>
                      <Text variant="headline_1" data-testid="home_header" tagName="h1">
                        {i18n.trans(t("flights_index_header_compare_book_ease"))}
                      </Text>

                      <Text variant="body_1">{i18n.trans(t("flights_index_header_discover_next_dream"))}</Text>
                    </Stack>

                    {/*
                   SearcboxController Context available here from the searchbox package
                   (controllerContext) => <SearchboxDesktop shouldScrollOnDatePickerOpen={true}></SearchboxDesktop>
                */}
                    <SearchboxController
                      i18n={i18n}
                      {...searchBoxProps}
                      initialValue={searchBoxInitialValue}
                      isRTL={isRTL}
                      trackFlyAnywhereWww={() => trackFlyAnywhereWww()}
                      onSearch={(searchParams) => {
                        trackLandingPageEvents("click", "searchbox");
                        searchBoxProps.onSearch(searchParams);
                      }}
                    >
                      {() => <SearchBoxHorizontalDefault />}
                    </SearchboxController>
                    <CrowdstrikeOutage />
                  </Stack>
                </Frame>
              </Container>
            </Frame>
          )}

          {canShowOnDesktop && (
            <Frame attributes={{ style: { width: "100%" } }} mb={0}>
              <UspBlock
                hideBackground={
                  isWinterDealsEligible && !!trackWithDefaultStage("flights_web_cat_winter_deals_promo", 1)
                }
                isPPCVariant={true}
              />
            </Frame>
          )}

          <GeniusAirlinesBanner />

          <NoSSR>
            <HomeRecentSearch />
          </NoSSR>

          {!!features.FLIGHTS_WEB_CAT_ENABLE_WEEKEND_GETAWAYS_UI ? <WeekendGetaways /> : null}

          <GeniusFlightsHomeBanner />

          <IndexCampaignBanner />

          {!!flights_web_cat_theme_based_destinations_www.trackWithDefaultStage() ? (
            <>
              <ThemeBasedDestinations isMobile={false} />
              <RemoteAppleCardIndex />
            </>
          ) : (
            <>
              <CheapFlights isMobile={false} />
              <RemoteAppleCardIndex />
              {!trackWithDefaultStage("flights_web_cat_blackout_trending_cities_www", 1) && (
                <TrendingDestinations isMobile={false} />
              )}
            </>
          )}

          {!!features.FLIGHTS_WEB_CAT_GENIUS_LOYALTY_RC ? <RemoteGeniusLoyalty /> : undefined}

          {showEsimBanner && (
            <Container style={{ margin: `${theme.units.spacing_8x} auto`, alignItems: "start" }}>
              <Title title={i18n.trans(t("flights_genius_section_header"))} variant="headline_2" titleTagName="h2" />
              <Wrapper elementRef={esimBannerRef} noBorder noPadding>
                <EsimBanner context="index" />
              </Wrapper>
            </Container>
          )}

          {!canShowOnDesktop && (
            <Frame attributes={{ style: { width: "100%" } }} mt={8} mb={8}>
              <UspBlock />
            </Frame>
          )}

          <FlightsInterlinkingDesktop />

          <Container style={{ margin: `${theme.units.spacing_8x} auto`, alignItems: "start" }}>
            <FrequentlyAskedQuestionsDesktop />
          </Container>
        </Frame>
      </AppShellDesktop>
      {!!features.FLIGHTS_WEB_CAT_GENIUS_LAUNCH_SHEET_RC ? <RemoteGeniusLaunchSheet /> : undefined}
      {trackWithDefaultStage("cm_post_trip_add_rewards_landsheet_to_flights_web", 1) ? (
        <RemoteRewardsLandSheet />
      ) : undefined}
    </Profiler>
  );
};

export default HomeDesktop;
