import { StoreState } from "store";

export const getHeaderIndex = (state: StoreState) => state.esim.index.esim?.header;
export const getSubheaderIndex = (state: StoreState) => state.esim.index.esim?.subHeader;
export const getCaptionIndex = (state: StoreState) => state.esim.index.esim?.caption;
export const getDiscountLinkIndex = (state: StoreState) => state.esim.index.esim?.discountLink;
export const getEsimIndex = (state: StoreState) => state.esim.index.esim;
export const getEsimEligibilityIndex = (state: StoreState) => state.esim.index.esim?.isEligible;
export const getFetchStatusIndex = (state: StoreState) => state.esim.index.fetchStatus;

export const getHeaderSearch = (state: StoreState) => state.esim.search.esim?.header;
export const getSubheaderSearch = (state: StoreState) => state.esim.search.esim?.subHeader;
export const getCaptionSearch = (state: StoreState) => state.esim.search.esim?.caption;
export const getDiscountLinkSearch = (state: StoreState) => state.esim.search.esim?.discountLink;
export const getEsimSearch = (state: StoreState) => state.esim.search.esim;
export const getEsimEligibilitySearch = (state: StoreState) => state.esim.search.esim?.isEligible;
export const getFetchStatusSearch = (state: StoreState) => state.esim.search.fetchStatus;

export const getHeaderFlight = (state: StoreState) => state.esim.flight.esim?.header;
export const getSubheaderFlight = (state: StoreState) => state.esim.flight.esim?.subHeader;
export const getCaptionFlight = (state: StoreState) => state.esim.flight.esim?.caption;
export const getDiscountLinkFlight = (state: StoreState) => state.esim.flight.esim?.discountLink;
export const getEsimFlight = (state: StoreState) => state.esim.flight.esim;
export const getEsimEligibilityFlight = (state: StoreState) => state.esim.flight.esim?.isEligible;
export const getFetchStatusFlight = (state: StoreState) => state.esim.flight.fetchStatus;

export const getHeaderPreOrder = (state: StoreState) => state.esim.preOrder.esim?.header;
export const getSubheaderPreOrder = (state: StoreState) => state.esim.preOrder.esim?.subHeader;
export const getCaptionPreOrder = (state: StoreState) => state.esim.preOrder.esim?.caption;
export const getDiscountLinkPreOrder = (state: StoreState) => state.esim.preOrder.esim?.discountLink;
export const getEsimPreOrder = (state: StoreState) => state.esim.preOrder.esim;
export const getEsimEligibilityPreOrder = (state: StoreState) => state.esim.preOrder.esim?.isEligible;
export const getFetchStatusPreOrder = (state: StoreState) => state.esim.preOrder.fetchStatus;

export const getHeaderOrder = (state: StoreState) => state.esim.order.esim?.header;
export const getSubheaderOrder = (state: StoreState) => state.esim.order.esim?.subHeader;
export const getCaptionOrder = (state: StoreState) => state.esim.order.esim?.caption;
export const getDiscountLinkOrder = (state: StoreState) => state.esim.order.esim?.discountLink;
export const getEsimOrder = (state: StoreState) => state.esim.order.esim;
export const getEsimEligibilityOrder = (state: StoreState) => state.esim.order.esim?.isEligible;
export const getFetchStatusOrder = (state: StoreState) => state.esim.order.fetchStatus;

export default {
  getHeaderIndex,
  getSubheaderIndex,
  getCaptionIndex,
  getDiscountLinkIndex,
  getEsimIndex,
  getEsimEligibilityIndex,
  getFetchStatusIndex,
  getHeaderSearch,
  getSubheaderSearch,
  getCaptionSearch,
  getDiscountLinkSearch,
  getEsimSearch,
  getEsimEligibilitySearch,
  getFetchStatusSearch,
  getHeaderFlight,
  getSubheaderFlight,
  getCaptionFlight,
  getDiscountLinkFlight,
  getEsimFlight,
  getEsimEligibilityFlight,
  getFetchStatusFlight,
  getHeaderOrder,
  getSubheaderOrder,
  getCaptionOrder,
  getDiscountLinkOrder,
  getEsimOrder,
  getEsimEligibilityOrder,
  getFetchStatusOrder,
  getHeaderPreOrder,
  getSubheaderPreOrder,
  getCaptionPreOrder,
  getDiscountLinkPreOrder,
  getEsimPreOrder,
  getEsimEligibilityPreOrder,
  getFetchStatusPreOrder
};
