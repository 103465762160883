/* eslint-disable no-restricted-syntax -- this line was auto generated, hence fix the issue timely */
import React from "react";
import { Sidebar } from "../../Container";
import SearchTabs from "../../SearchTabs";
import { SkeletonFlightCard } from "../SkeletonFlightCard";
import { SkeletonListKeyValue } from "../SkeletonListKeyValue";
import { SearchLoadingMessage } from "components/elements/SearchLoadingMessage";
import Frame from "components/elements/Frame";
import flights_web_reduce_spacing_sr_ddot from "utils/experiments/funnel/flights_web_reduce_spacing_sr_ddot";

interface Props {
  text?: string;
}

export const SkeletonSearchContent = ({ count = 4 }: { count?: number }) => {
  return (
    <React.Fragment>
      <SearchLoadingMessage />
      {Array.from({ length: count }).map((_, i) =>
        flights_web_reduce_spacing_sr_ddot.trackWithDefaultStage() ? (
          <Frame key={i} mt={i === 0 ? 4 : 0} mb={2}>
            <SkeletonFlightCard />
          </Frame>
        ) : (
          <Frame key={i} mt={3} mb={3}>
            <SkeletonFlightCard />
          </Frame>
        )
      )}
    </React.Fragment>
  );
};

const SkeletonSearchDesktop: React.FunctionComponent<Props> = () => {
  return (
    <Frame direction="row">
      {/* START: Left Column */}
      <Sidebar pt={flights_web_reduce_spacing_sr_ddot.trackWithDefaultStage() ? 0 : 4}>
        <Frame p={4}>
          <SkeletonListKeyValue amount={12} />
        </Frame>
      </Sidebar>
      {/* END: Left Column */}

      {/* START: Right Column */}
      <Frame grow={1} pt={flights_web_reduce_spacing_sr_ddot.trackWithDefaultStage() ? 0 : 4} pb={0} pl={6}>
        <SearchTabs showBestRankingInfo fill />
        <SkeletonSearchContent />
      </Frame>
      {/* END: Right Column */}
    </Frame>
  );
};

export default SkeletonSearchDesktop;
