export enum PaxTypes {
  ADULT = "ADULT",
  KID = "KID"
}

export const C360_ACTION_VERSION = "1.1.0";

export enum CsxpPlacementPages {
  FLIGHT_MANAGE_BOOKING = "FLIGHT__MANAGE_BOOKING",
  FLIGHTS_CONFIRMATION = "FLIGHT__CONFIRMATION",
  FLIGHTS_SEARCHRESULTS = "FLIGHT__SEARCHRESULTS",
  FLIGHTS_FLIGHTDETAILS = "FLIGHT__FLIGHTDETAILS",
  FLIGHTS_CHECKOUT_TICKET_TYPE = "FLIGHT__CHECKOUT_TICKET_TYPE",
  FLIGHTS_CHECKOUT3 = "FLIGHT__CHECKOUT"
}

export enum CsxpVerticals {
  FLIGHTS = "FLIGHT"
}

export enum CsxpPlacementNames {
  PLAN_AHEAD = "PLAN_AHEAD",
  MODAL = "MODAL",
  CONNECTOR = "CONNECTOR",
  INLINE_BANNER_INFO = "INLINE_BANNER_INFO"
}
