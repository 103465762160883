/* eslint-disable no-restricted-syntax -- this line was auto generated, hence fix the issue timely */
import React, { useEffect } from "react";
import { AspectRatio, Icon, List, ListItem, Text } from "@bookingcom/bui-react";
import { useI18n, t } from "@bookingcom/lingojs-react";
import useUserAgent from "hooks/useUserAgent";
import { customGoal, stage } from "@flights/et-universal";
import useCreateUrl from "hooks/useCreateUrl";
import Container from "components/elements/Container";
import useGlobalContext from "hooks/useGlobalContext";
import styles from "./HomeRecentSearch.module.css";
import { HistoryRecentIcon } from "@bookingcom/bui-assets-react/streamline/index.js";
import useHomeRecentSearch from "./useHomeRecentSearch.controller";
import useProfiler from "hooks/useProfiler";
import { trackExperiment } from "utils/et";

export default function HomeRecentSearchList() {
  const { apiPrefix } = useGlobalContext();
  const i18n = useI18n();
  const { isMobile } = useUserAgent();
  const { createUrl } = useCreateUrl();
  const { isMeta } = useProfiler();
  const { images, searchHistoryList, generateSearchUrlFromSearchHistory } = useHomeRecentSearch();

  const canRender = !!searchHistoryList.length && !!trackExperiment("flights_web_mdot_home_recent_search_carousel");

  useEffect(() => {
    if (searchHistoryList.length === 0) return;
    stage("flights_web_mdot_home_recent_search_carousel", 1);
  }, [searchHistoryList, isMobile, isMeta]);

  if (!canRender) return null;

  return (
    <Container className={styles.bg} pb={4}>
      <div style={{ paddingBottom: "var(--bui_spacing_4x)" }}>
        <ListItem spacing="small" startSlot={<Icon size="large" svg={HistoryRecentIcon} />}>
          <Text tagName="h2" variant="emphasized_1">
            <b>{i18n.trans(t("flights_continue_search_title"))}</b>
          </Text>
        </ListItem>
      </div>
      <List divided rowSpacing="small">
        {searchHistoryList.map((item, idx) => (
          <ListItem
            verticalAlignment="start"
            key={item.title || "" + item.subtitle + idx}
            startSlot={
              <AspectRatio
                attributes={{
                  style: {
                    borderRadius: "var(--bui_border_radius_100)",
                    backgroundColor: "var(--bui_color_foreground_inverted)",
                    backgroundPosition: "center center",
                    backgroundSize: "cover",
                    backgroundImage: `url("https://q-xx.bstatic.com${images?.[item?.photoIata || ""]?.["square210"]}")`
                  }
                }}
                ratio="1:1"
                width="32px"
              />
            }
          >
            <a
              style={{ color: "inherit", textDecoration: "none" }}
              href={apiPrefix + createUrl(generateSearchUrlFromSearchHistory(item.searchhistoryItem))}
              onClick={() => {
                customGoal("flights_web_mdot_home_recent_search_carousel", 1);
              }}
            >
              <Text variant="emphasized_2">{item.title}</Text>
              <Text color="neutral_alt" variant="body_2">
                {item.subtitle}
              </Text>
            </a>
          </ListItem>
        ))}
      </List>
    </Container>
  );
}
