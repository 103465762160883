import React, { useCallback, useMemo, useRef } from "react";
import { Accordion, Card, Divider, Grid, GridColumn, Stack, Title, Text, useViewport } from "@bookingcom/bui-react";
import { FaqItem, getFaqData } from "./FrequentlyAskedQuestionsData";
import { t, useI18n } from "@bookingcom/lingojs-react";
import styles from "./FrequentlyAskedQuestions.desktop.module.css";
import { useTrackLandingPageViewport } from "hooks/c360/useLandingPageEvents";
import { customGoal, trackWithDefaultStage } from "@flights/et-universal";

/**
 * FAQ block on Flights index page on Desktop web.
 */
const FrequentlyAskedQuestionsDesktop = ({ isMobile }: { isMobile?: boolean }) => {
  const i18n = useI18n();
  const faqItemList: FaqItem[] = getFaqData(i18n);
  const scrollRef = useRef<HTMLDivElement>(null);
  const { isSmall, isMedium } = useViewport();

  useTrackLandingPageViewport(scrollRef, "faq");

  const midPoint = useMemo(() => Math.ceil(faqItemList.length / 2), [faqItemList]);

  const handleOpen = useCallback(
    (id: number) => {
      if (id > 5) {
        return;
      }

      if (isMobile) {
        customGoal("flights_web_cat_faqs_accordion_mdot", id);
      } else {
        customGoal("flights_web_cat_faqs_accordion_www", id);
      }
    },
    [isMobile]
  );

  const renderFaqGrid = () => {
    return (
      <Grid bleed={false}>
        {faqItemList.map((faq, index) => (
          <GridColumn size={4} key={index}>
            <div
              className={styles.faqCardContainer}
              itemScope
              itemProp="mainEntity"
              itemType="https://schema.org/Question"
            >
              <Card variant="neutral" className={styles.faqCard}>
                <Title
                  className={styles.question}
                  variant="strong_1"
                  title={faq.question}
                  attributes={{ itemProp: "name" }}
                />
                <div itemScope itemProp="acceptedAnswer" itemType="https://schema.org/Answer">
                  <Text attributes={{ itemProp: "text" }} variant="body_2">
                    {faq.answer}
                  </Text>
                </div>
              </Card>
            </div>
          </GridColumn>
        ))}
      </Grid>
    );
  };

  const FaqColumn = useCallback(
    ({ items }: { items: FaqItem[] }) => (
      <GridColumn size={6}>
        <Card>
          <Stack gap={2}>
            {items.map((faq, index) => (
              <React.Fragment key={index}>
                <div itemScope itemProp="mainEntity" itemType="https://schema.org/Question">
                  <Accordion
                    titleContent={<Title title={faq.question} variant="strong_1" attributes={{ itemProp: "name" }} />}
                    onOpen={() => handleOpen(faq.id)}
                  >
                    <div itemScope itemProp="acceptedAnswer" itemType="https://schema.org/Answer">
                      <Text variant="body_2" attributes={{ itemProp: "text" }}>
                        {faq.answer}
                      </Text>
                    </div>
                  </Accordion>
                </div>
                {index < items.length - 1 && <Divider />}
              </React.Fragment>
            ))}
          </Stack>
        </Card>
      </GridColumn>
    ),
    [handleOpen]
  );

  const renderFaqGridWithAccordion = useCallback(() => {
    if (isSmall || isMedium) {
      return (
        <div itemScope itemType="https://schema.org/FAQPage">
          <Grid>
            <GridColumn size={12}>
              <Card>
                <Stack gap={2}>
                  {faqItemList.map((faq, index) => (
                    <React.Fragment key={index}>
                      <div itemScope itemProp="mainEntity" itemType="https://schema.org/Question">
                        <Accordion
                          titleContent={
                            <Title title={faq.question} variant="strong_1" attributes={{ itemProp: "name" }} />
                          }
                          onOpen={() => handleOpen(faq.id)}
                        >
                          <div itemScope itemProp="acceptedAnswer" itemType="https://schema.org/Answer">
                            <Text variant="body_2" attributes={{ itemProp: "text" }}>
                              {faq.answer}
                            </Text>
                          </div>
                        </Accordion>
                      </div>
                      {index < faqItemList.length - 1 && <Divider />}
                    </React.Fragment>
                  ))}
                </Stack>
              </Card>
            </GridColumn>
          </Grid>
        </div>
      );
    }

    return (
      <div itemScope itemType="https://schema.org/FAQPage">
        <Grid>
          <FaqColumn items={faqItemList.slice(0, midPoint)} />
          <FaqColumn items={faqItemList.slice(midPoint)} />
        </Grid>
      </div>
    );
  }, [FaqColumn, faqItemList, handleOpen, isMedium, isSmall, midPoint]);

  return (
    <div ref={scrollRef}>
      <Title
        title={i18n.trans(t("seo_flights_new_index_lp_faq_header"))}
        variant="headline_2"
        className={styles.title}
        titleTagName="h2"
      />
      <div itemScope itemType="https://schema.org/FAQPage">
        {trackWithDefaultStage("flights_web_cat_faqs_accordion_www", 1) ||
        (isMobile && trackWithDefaultStage("flights_web_cat_faqs_accordion_mdot", 1))
          ? renderFaqGridWithAccordion()
          : renderFaqGrid()}
      </div>
    </div>
  );
};
export default FrequentlyAskedQuestionsDesktop;
