// eslint-disable-next-line import/no-deprecated -- this line was auto generated, hence fix the issue timely
import { createTrackingUtils } from "..";

// eslint-disable-next-line import/no-deprecated -- this line was auto generated, hence fix the issue timely
const utils = createTrackingUtils({
  name: "flights_apex_web_move_baggage_features_to_server",
  stages: {
    main: 1,
    sellable_required: 2,
    sr_with_bf: 3,
    bf_page: 4,
    check_pay_bf: 5
  },
  defaultStage: 1,
  goals: {},
  goalsWithValue: []
});

export default utils;
