/* start - flights_alternative_fares_for_sold_out_errors_web */
import { useCallback } from "react";
import { flightsSessionStore } from "@flights/web-api-utils-universal";

const key = "offer_alternative_fares_params";

type Params = {
  searchedAt: number;
  priceInSearch: string;
  excludedAncillaries: string;
  salesCountry: string;
};

const useAlternativeFaresParams = () => {
  const setAlternativeFaresParams = useCallback(<T extends keyof Params>(type: T, data: Params[T]) => {
    const currentData: Params = flightsSessionStore.get(key);
    flightsSessionStore.set(key, { ...currentData, [type]: data });
  }, []);
  const getAlternativeFaresParams = useCallback((): Params | undefined => flightsSessionStore.get(key), []);
  const clearAlternativeFaresParams = useCallback(() => {
    flightsSessionStore.unset(key);
  }, []);

  return {
    setAlternativeFaresParams,
    getAlternativeFaresParams,
    clearAlternativeFaresParams
  };
};

export default useAlternativeFaresParams;
/* end - flights_alternative_fares_for_sold_out_errors_web */
