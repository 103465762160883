import { useCallback, useMemo } from "react";
import { useLocation, useHistory } from "react-router-dom";
// eslint-disable-next-line import/no-deprecated -- this line was auto generated, hence fix the issue timely
import { useStore, useActions } from "../../../store";
import { actions as searchActions } from "store/search/actions";
import useFormatPrice from "hooks/useFormatPrice";
import { useI18n, t } from "@bookingcom/lingojs-react";
import { FILTERS_STOPS_MAX } from "../../../constants";
import { UIFlightTimesAgg } from "@flights/types";
import useGlobalContext from "hooks/useGlobalContext";
import useEventTracking from "hooks/useEventTracking";
import { SBSortingOrder } from "@bookingcom/flights-searchbox";

const useSearchFiltersController = () => {
  const i18n = useI18n();
  // eslint-disable-next-line import/no-deprecated -- this line was auto generated, hence fix the issue timely
  const store = useStore();
  // eslint-disable-next-line import/no-deprecated -- this line was auto generated, hence fix the issue timely
  const actions = useActions(searchActions);
  const { formatPrice } = useFormatPrice();
  const { requestId } = useGlobalContext();
  const trackV2 = useEventTracking("search_results", requestId);
  const filtersState = store.search.filtersDraft;
  const { availableFilters = {} } = store.searchResults;
  const { type, searchSegments } = store.search;

  const trackFiltersEvent = useCallback(
    (filters: any) => {
      const selectedFiltersTrack = {
        airlines: !!filters?.airlines?.length,
        duration: filters?.duration !== undefined,
        stops: filters?.stops !== undefined || filters?.multiSelectStops !== undefined,
        maxBudget: filters?.maxBudget !== undefined,
        maxBudgetPerAdult: filters?.maxBudgetPerAdult !== undefined,
        maxLayoverDuration: filters?.maxLayoverDuration !== undefined,
        departureIntervals: !!filters?.departureIntervals?.flat()?.length,
        arrivalIntervals: !!filters?.arrivalIntervals?.flat()?.length,
        flightTimes: !!filters?.flightTimes?.length,
        shortLayoverConnection: !!filters?.shortLayoverConnection,
        includedBaggage: !!filters?.includedBaggage?.length,
        airports: !!filters?.airports?.length,
        preferSameAirport: !!filters?.preferSameAirport
      };
      trackV2("select_filters", selectedFiltersTrack);
    },
    [trackV2]
  );

  const setFilters = useCallback(
    (filters: Partial<typeof filtersState>) => {
      const result = { ...filtersState, ...filters };
      trackFiltersEvent(result);
      actions.setFiltersDraft(result);
    },
    [filtersState, trackFiltersEvent, actions]
  );

  // sort
  // ===================================================================== start
  const history = useHistory();
  const location = useLocation();
  const commitSort = useCallback(
    (s: SBSortingOrder = "BEST") => {
      const params = new URLSearchParams(location.search);
      params.set("sort", s);
      params.delete("page");
      history.replace({
        pathname: location.pathname,
        search: params.toString()
      });
    },
    [history, location.pathname, location.search]
  );
  const sortValue = (new URLSearchParams(location.search).get("sort") || "BEST") as SBSortingOrder;
  const sortOptions = [
    {
      value: "BEST" as SBSortingOrder,
      label: i18n.trans(t("flights_search_sort_best")),
      description: i18n.trans(t("flights_search_sort_best_tooltip"))
    },
    { value: "CHEAPEST" as SBSortingOrder, label: i18n.trans(t("flights_search_sort_cheapest")) },
    { value: "FASTEST" as SBSortingOrder, label: i18n.trans(t("flights_search_sort_fastest")) }
  ];
  const sortTouched = sortValue !== "BEST";
  const sort = {
    set: commitSort,
    reset: commitSort,
    value: sortValue,
    options: sortOptions,
    touched: sortTouched
  };
  // ======================================================================= end

  // duration filter
  // ===================================================================== start
  const durationSet = useCallback((duration: number) => setFilters({ duration }), [setFilters]);
  const durationReset = useCallback(() => setFilters({ duration: undefined }), [setFilters]);
  const durationOptions = {
    min: availableFilters.durationMin || 0,
    max: availableFilters.durationMax || 0
  };
  const durationValue = filtersState.duration || availableFilters.durationMax || 0;
  const durationTouched = durationValue > 0 && durationValue !== durationOptions.max;
  const duration = {
    options: durationOptions,
    value: durationValue,
    touched: durationTouched,
    set: durationSet,
    reset: durationReset
  };
  // ======================================================================= end

  // stops filter
  // ===================================================================== start
  const stopsSet = useCallback(
    (stops: number) => setFilters({ stops: stops === -1 ? undefined : stops }),
    [setFilters]
  );
  const stopsReset = useCallback(() => setFilters({ stops: undefined }), [setFilters]);
  const stopsOptions =
    availableFilters.stops
      ?.map((stopOtion) => ({
        count: stopOtion.count,
        label:
          stopOtion.numberOfStops === 0
            ? i18n.trans(t("flights_filters_stops_direct"))
            : i18n.trans(t("flights_filters_stops_one_max")),
        value: stopOtion.numberOfStops,
        price: stopOtion.minPrice ? formatPrice(stopOtion.minPrice) : "",
        roundPrice: stopOtion.minPriceRound ? formatPrice(stopOtion.minPriceRound, { decimalPlaces: 0 }) : ""
      }))
      .filter((option) => option.value <= FILTERS_STOPS_MAX) || [];

  const anyOptionPrice =
    availableFilters.stops?.[availableFilters.stops?.length - 1]?.minPrice || availableFilters.minPrice;
  const anyOptionRoundPrice =
    availableFilters.stops?.[availableFilters.stops?.length - 1]?.minPriceRound || availableFilters.minRoundPrice;

  stopsOptions.unshift({
    value: -1,
    label: i18n.trans(t("flights_filters_stops_any")),
    count: availableFilters.totalCount || 0,
    price: anyOptionPrice ? formatPrice(anyOptionPrice) : "",
    roundPrice: anyOptionRoundPrice ? formatPrice(anyOptionRoundPrice, { decimalPlaces: 0 }) : ""
  });
  const stopsValue = filtersState.stops === undefined ? -1 : filtersState.stops; // any stop, simillat as undefined
  const stopsTouched = stopsValue !== -1 && stopsValue !== undefined;
  const stops = {
    options: stopsOptions,
    value: stopsValue,
    touched: stopsTouched,
    set: stopsSet,
    reset: stopsReset
  };
  // ======================================================================= end

  // airlines filter
  // ===================================================================== start
  const airlinesSet = useCallback(
    (airlines: string[]) => {
      const isAll = airlines.length === availableFilters.airlines?.length;
      setFilters({ airlines: isAll ? undefined : airlines });
    },
    [setFilters, availableFilters.airlines]
  );
  const airlinesReset = useCallback(() => setFilters({ airlines: undefined }), [setFilters]);
  const airlinesOptions =
    availableFilters.airlines?.map((airline) => ({
      label: airline.name,
      count: airline.count,
      value: airline.iataCode,
      price: airline.minPrice ? formatPrice(airline.minPrice) : "",
      roundPrice: airline.minPrice ? formatPrice(airline.minPrice, { decimalPlaces: 0 }) : ""
    })) || [];
  const airlinesValue =
    filtersState.airlines === undefined ? airlinesOptions.map((a) => a.value) : filtersState.airlines;
  const airlinesTouched =
    filtersState.airlines !== undefined && filtersState.airlines.length !== airlinesOptions.length;
  const airlines = {
    options: airlinesOptions,
    value: airlinesValue,
    set: airlinesSet,
    reset: airlinesReset,
    touched: airlinesTouched
  };
  // ======================================================================= end

  // flights times filter
  // ===================================================================== start
  const flightTimesSet = useCallback((flightTimes: UIFlightTimesAgg) => setFilters({ flightTimes }), [setFilters]);
  const flightTimesReset = useCallback(() => setFilters({ flightTimes: undefined }), [setFilters]);
  const getFlightTimesTitle = useCallback(
    (idx: number) => {
      const segment = searchSegments[idx];
      return type === "MULTISTOP"
        ? `${segment.from[0].code} - ${segment.to[0].code}`
        : idx === 0
        ? i18n.trans(t("flights_filters_times_outbound"))
        : i18n.trans(t("flights_filters_times_return"));
    },
    [searchSegments, type, i18n]
  );
  const getFlightTimesLabel = useCallback(
    (idx: number, name: string) => {
      const isDeparture = name === "departure";
      const isMultiStop = type === "MULTISTOP";
      const segmentIdx = isMultiStop ? idx : 0;
      const fromNames = searchSegments?.[segmentIdx]?.["from"]?.map((loc) => loc.name) || [];
      const toNames = searchSegments?.[segmentIdx]?.["to"]?.map((loc) => loc.name) || [];
      let locationsNames = isDeparture ? fromNames : toNames;
      if (!isMultiStop && idx > 0) locationsNames = isDeparture ? toNames : fromNames; // return is reverse of depart
      return isDeparture
        ? i18n.trans(t("flights_filter_departs_time", { variables: { city_name: locationsNames.join(", ") } }))
        : i18n.trans(t("flights_filter_arrives_time", { variables: { city_name: locationsNames.join(", ") } }));
    },
    [searchSegments, type, i18n]
  );
  const flightTimesOptions = useMemo(
    () =>
      availableFilters.flightTimes?.map((times) => ({
        departure: times.departure.map((time) => ({ ...time, selected: false })) || [],
        arrival: times.arrival.map((time) => ({ ...time, selected: false })) || []
      })) || [],
    [availableFilters]
  );
  const flightTimesValue = useMemo(
    () => (filtersState.flightTimes?.length > 0 ? [...filtersState.flightTimes] : [...flightTimesOptions]),
    [filtersState.flightTimes, flightTimesOptions]
  );

  const flightTimesCount = useMemo(
    () =>
      flightTimesValue.map((segment) => [...segment.arrival, ...segment.departure].filter((t) => t.selected)).flat(2)
        .length || 0,
    [flightTimesValue]
  );
  const flightTimesTouched = useMemo(() => flightTimesCount > 0, [flightTimesCount]);

  const flightTimes = useMemo(
    () => ({
      options: flightTimesOptions,
      value: flightTimesValue,
      touched: flightTimesTouched,
      count: flightTimesCount,
      set: flightTimesSet,
      reset: flightTimesReset,
      getLabel: getFlightTimesLabel,
      getTitle: getFlightTimesTitle
    }),
    [
      flightTimesOptions,
      flightTimesValue,
      flightTimesTouched,
      flightTimesCount,
      flightTimesSet,
      flightTimesReset,
      getFlightTimesLabel,
      getFlightTimesTitle
    ]
  );
  // ======================================================================= end

  // flights times filter
  // this is an experiment flighes_web_filters_price_per_adult
  // ===================================================================== start
  const budgetPerAdultSet = useCallback(
    (value: number) => {
      const isMax = value === availableFilters.budgetPerAdult?.max?.units;
      setFilters({ maxBudgetPerAdult: isMax ? undefined : value });
    },
    [setFilters, availableFilters.budgetPerAdult]
  );
  const budgetPerAdultReset = useCallback(() => {
    setFilters({ maxBudgetPerAdult: undefined });
  }, [setFilters]);
  const budgetPerAdultOptions = {
    min: availableFilters.budgetPerAdult?.min || { nanos: 0, units: 0, currencyCode: "" },
    max: availableFilters.budgetPerAdult?.max || { nanos: 0, units: 0, currencyCode: "" }
  };
  const budgetPerAdultValue = filtersState.maxBudgetPerAdult || budgetPerAdultOptions.max?.units;
  const budgetPerAdultTouched = budgetPerAdultValue > 0 && budgetPerAdultValue !== budgetPerAdultOptions.max?.units;
  const budgetPerAdult = {
    options: budgetPerAdultOptions,
    value: budgetPerAdultValue,
    touched: budgetPerAdultTouched,
    set: budgetPerAdultSet,
    reset: budgetPerAdultReset
  };
  // ======================================================================= end

  // Popular Filters
  // these are some picked filters from the all available filters grouped
  // to be displayed as "popular filters" in top of all filters.
  // ===================================================================== start
  // -- popularAirlines --------------------------------------------------------
  const fakeDisabledState = useMemo(
    () => ({
      count: 0,
      set: () => {},
      reset: () => {},
      checked: false,
      disabled: true,
      names: []
    }),
    []
  );
  const popularAirlinesOptions = useMemo(() => {
    if (!availableFilters.airlines || availableFilters.airlines.length <= 5) return undefined;
    const popular = [...availableFilters.airlines].splice(0, 5);
    return {
      names: popular.map((_) => _.name),
      iata: popular.map((_) => _.iataCode),
      count: Math.max(...popular.map((_) => _.count || 0))
    };
  }, [availableFilters]);
  const isPopularAirlines = useMemo(
    () => popularAirlinesOptions && popularAirlinesOptions?.count > 0,
    [popularAirlinesOptions]
  );
  const setPopularAirlines = useCallback(
    () => setFilters({ airlines: popularAirlinesOptions?.iata }),
    [setFilters, popularAirlinesOptions]
  );
  const resetPopularAirlines = useCallback(() => setFilters({ airlines: undefined }), [setFilters]);
  const isPopularAirlinesChecked = useMemo(() => {
    return !!popularAirlinesOptions?.iata?.every((_) => filtersState.airlines?.includes(_));
  }, [filtersState, popularAirlinesOptions]);
  const popularAirlines = useMemo(() => {
    if (!isPopularAirlines) return fakeDisabledState;
    return {
      names: popularAirlinesOptions?.names,
      set: setPopularAirlines,
      reset: resetPopularAirlines,
      checked: isPopularAirlinesChecked,
      count: popularAirlinesOptions?.count,
      disabled: false
    };
  }, [
    isPopularAirlines,
    setPopularAirlines,
    resetPopularAirlines,
    isPopularAirlinesChecked,
    popularAirlinesOptions,
    fakeDisabledState
  ]);

  // -- cabinBaggage -----------------------------------------------------------
  const cabinBaggageOptions = useMemo(() => {
    return availableFilters.baggage?.find((_) => _.baggageType === "CABIN");
  }, [availableFilters]);
  const setCabinBaggage = useCallback(() => {
    const i = filtersState.includedBaggage || [];
    const includedBaggage = i.includes("CABIN") ? filtersState.includedBaggage : [...i, "CABIN"];
    setFilters({ includedBaggage });
  }, [setFilters, filtersState]);
  const resetCabinBaggage = useCallback(() => {
    setFilters({ includedBaggage: (filtersState.includedBaggage || []).filter((_) => _ !== "CABIN") });
  }, [setFilters, filtersState]);
  const isCabinBaggageChecked = useMemo(() => (filtersState.includedBaggage || []).includes("CABIN"), [filtersState]);
  const countCabinBaggage = useMemo(() => cabinBaggageOptions?.count, [cabinBaggageOptions]);
  const cabinBaggage = useMemo(() => {
    if (!cabinBaggageOptions?.count) return fakeDisabledState;
    return {
      set: setCabinBaggage,
      reset: resetCabinBaggage,
      checked: isCabinBaggageChecked,
      count: countCabinBaggage,
      disabled: false
    };
  }, [
    setCabinBaggage,
    resetCabinBaggage,
    isCabinBaggageChecked,
    countCabinBaggage,
    cabinBaggageOptions,
    fakeDisabledState
  ]);

  // -- earlyDeparture ---------------------------------------------------------
  const earlyDepartureOptions = useMemo(() => {
    const available = availableFilters?.flightTimes?.[0]?.departure?.find(
      (_) => _.start === "00:00" && _.end === "05:59"
    );
    const state = filtersState?.flightTimes?.[0]?.departure?.find((_) => _.start === "00:00" && _.end === "05:59");
    return { ...state, count: available?.count || state?.count };
  }, [availableFilters, filtersState]);
  const setEarlyDeparture = useCallback(() => {
    const value = flightTimes.value.map((flightTime, idx) => {
      if (idx !== 0) return { ...flightTime };
      return {
        ...flightTime,
        departure: flightTime.departure.map((time) => {
          if (time.start !== "00:00" || time.end !== "05:59") return { ...time };
          return { ...time, selected: true };
        })
      };
    });
    flightTimesSet(value);
  }, [flightTimes, flightTimesSet]);
  const resetEarlyDeparture = useCallback(() => {
    const value = flightTimes.value.map((flightTime, idx) => {
      if (idx !== 0) return { ...flightTime };
      return {
        ...flightTime,
        departure: flightTime.departure.map((time) => {
          if (time.start !== "00:00" || time.end !== "05:59") return { ...time };
          return { ...time, selected: false };
        })
      };
    });
    flightTimesSet(value);
  }, [flightTimes, flightTimesSet]);
  const earlyDeparture = useMemo(() => {
    if (!earlyDepartureOptions?.count) return fakeDisabledState;
    return {
      set: setEarlyDeparture,
      reset: resetEarlyDeparture,
      checked: earlyDepartureOptions.selected,
      count: earlyDepartureOptions.count,
      disabled: false
    };
  }, [setEarlyDeparture, resetEarlyDeparture, earlyDepartureOptions, fakeDisabledState]);

  // -- morningDeparture -------------------------------------------------------
  const morningDepartureOptions = useMemo(() => {
    const available = availableFilters?.flightTimes?.[0]?.departure?.find(
      (_) => _.start === "06:00" && _.end === "11:59"
    );
    const state = filtersState?.flightTimes?.[0]?.departure?.find((_) => _.start === "06:00" && _.end === "11:59");
    return { ...state, count: available?.count || state?.count };
  }, [availableFilters, filtersState]);
  const setMorningDeparture = useCallback(() => {
    const value = flightTimes.value.map((flightTime, idx) => {
      if (idx !== 0) return { ...flightTime };
      return {
        ...flightTime,
        departure: flightTime.departure.map((time) => {
          if (time.start !== "06:00" || time.end !== "11:59") return { ...time };
          return { ...time, selected: true };
        })
      };
    });
    flightTimesSet(value);
  }, [flightTimes, flightTimesSet]);
  const resetMorningDeparture = useCallback(() => {
    const value = flightTimes.value.map((flightTime, idx) => {
      if (idx !== 0) return { ...flightTime };
      return {
        ...flightTime,
        departure: flightTime.departure.map((time) => {
          if (time.start !== "06:00" || time.end !== "11:59") return { ...time };
          return { ...time, selected: false };
        })
      };
    });
    flightTimesSet(value);
  }, [flightTimes, flightTimesSet]);
  const morningDeparture = useMemo(() => {
    if (!morningDepartureOptions?.count) return fakeDisabledState;
    return {
      set: setMorningDeparture,
      reset: resetMorningDeparture,
      checked: morningDepartureOptions.selected,
      count: morningDepartureOptions.count,
      disabled: false
    };
  }, [setMorningDeparture, resetMorningDeparture, morningDepartureOptions, fakeDisabledState]);

  // -- lateArrival ------------------------------------------------------------
  const lateArrivalOptions = useMemo(() => {
    if (type === "MULTISTOP") return undefined;
    const available = availableFilters?.flightTimes?.[0]?.arrival?.find(
      (_) => _.start === "18:00" && _.end === "23:59"
    );
    const state = filtersState?.flightTimes?.[0]?.arrival?.find((_) => _.start === "18:00" && _.end === "23:59");
    return { ...state, count: available?.count || state?.count };
  }, [type, availableFilters, filtersState]);
  const setLateArrival = useCallback(() => {
    const value = flightTimes.value.map((flightTime, idx) => {
      if (idx !== 0) return { ...flightTime };
      return {
        ...flightTime,
        arrival: flightTime.arrival.map((time) => {
          if (time.start !== "18:00" || time.end !== "23:59") return { ...time };
          return { ...time, selected: true };
        })
      };
    });
    flightTimesSet(value);
  }, [flightTimes, flightTimesSet]);
  const resetLateArrival = useCallback(() => {
    const value = flightTimes.value.map((flightTime, idx) => {
      if (idx !== 0) return { ...flightTime };
      return {
        ...flightTime,
        arrival: flightTime.arrival.map((time) => {
          if (time.start !== "18:00" || time.end !== "23:59") return { ...time };
          return { ...time, selected: false };
        })
      };
    });
    flightTimesSet(value);
  }, [flightTimes, flightTimesSet]);
  const lateArrival = useMemo(() => {
    if (!lateArrivalOptions?.count) return fakeDisabledState;
    return {
      set: setLateArrival,
      reset: resetLateArrival,
      checked: lateArrivalOptions.selected,
      count: lateArrivalOptions.count,
      disabled: false
    };
  }, [setLateArrival, resetLateArrival, lateArrivalOptions, fakeDisabledState]);

  // -- shortLayover -----------------------------------------------------------
  const shortLayoverConnectionOptions = useMemo(() => availableFilters.shortLayoverConnection, [availableFilters]);
  const setShortLayoverConnection = useCallback(() => setFilters({ shortLayoverConnection: true }), [setFilters]);
  const resetShortLayoverConnection = useCallback(
    () => setFilters({ shortLayoverConnection: undefined }),
    [setFilters]
  );
  const isShortLayoverConnectionChecked = useMemo(() => !!filtersState.shortLayoverConnection, [filtersState]);
  const shortLayoverConnection = useMemo(() => {
    if (!shortLayoverConnectionOptions) return fakeDisabledState;
    return {
      count: shortLayoverConnectionOptions?.count,
      set: setShortLayoverConnection,
      reset: resetShortLayoverConnection,
      checked: isShortLayoverConnectionChecked,
      disabled: false
    };
  }, [
    shortLayoverConnectionOptions,
    setShortLayoverConnection,
    resetShortLayoverConnection,
    isShortLayoverConnectionChecked,
    fakeDisabledState
  ]);
  // ======================================================================= end

  const resetAllFilters = useCallback(() => {
    actions.resetFilters();
  }, [actions]);

  const appliedFiltersCount = [
    durationTouched,
    stopsTouched,
    airlinesTouched,
    flightTimesTouched,
    budgetPerAdultTouched
  ].filter(Boolean).length;

  return {
    resetAllFilters,
    appliedFiltersCount,
    duration,
    stops,
    airlines,
    flightTimes,
    sort,
    budgetPerAdult,
    // popular filters
    popularAirlines,
    cabinBaggage,
    earlyDeparture,
    morningDeparture,
    lateArrival,
    shortLayoverConnection
  };
};

export default useSearchFiltersController;
