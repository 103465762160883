import React, { FC, ReactNode, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { t } from "@bookingcom/lingojs-core";
import { I18nChildContext, useI18n } from "@bookingcom/lingojs-react";

import FlightCardCarriers, { CarrierNames } from "./FlightCardCarriers";
import FlightCardCarriersV2 from "./FlightCardCarriersV2";
import FlightCardPrice from "./FlightCardPrice";
import { Button, Card, Icon, Stack } from "@bookingcom/bui-react";
import { FlightBadgeVariant, UIFlightData, UIFlightSegment, UIOrder, UITripRebookOption } from "@flights/types";

import BaseFareAttributes from "./BaseFareAttributes";
import BaseFareName from "./BaseFareName";
import FlightCardLuggage from "./FlightCardLuggage";

import useLocaleContext from "hooks/useLocaleContext";
import { trackExperiment } from "utils/et";
import useAirlineLogos from "../../../../hooks/useAirlineLogos";
// eslint-disable-next-line import/no-deprecated -- this line was auto generated, hence fix the issue timely
import { useStore } from "../../../../store";
import { SBSearchType } from "@bookingcom/flights-searchbox";
import { ArrowNavDownIcon, ArrowNavUpIcon } from "@bookingcom/bui-assets-react/streamline/index.js";
import { AnimatePresence, motion } from "framer-motion";
import { FareSelectorDesktop } from "components/elements/FareSelector/components/FareSelector.desktop";
import useIsInViewport from "hooks/useIsInViewport";
import useFetchBrandedFares from "components/elements/FareSelector/hooks/useFetchBrandedFares";
import { RebookCostBreakdown } from "components/elements/RebookFlow/RebookCostBreakdown/RebookCostBreakdown";
import FlightCardFullLuggageData from "./FlightCardFullLuggageData";
import { isFeatureRunningClientSide } from "utils/features";
import { FlightCardHighlight, FlightCardHighlightStyle } from "./FlightCardHighlight";
import { FlightCardBadges } from "./FlightCardBadges";
import { FlightCardBadge } from "./FlightCardBadge";
import Frame, { FrameProps } from "components/elements/Frame";
import { getCampaignBadgesConfig, getCampaignBestOfferHighlightConfig } from "utils/flightCampaignConfig";
import styles from "./FlightCardBound.desktop.module.css";
import trackSwapPriceBaggage from "utils/experiments/funnel/flights_web_swap_price_baggage_sr_desktop";
import FlightCardLuggagePopover from "./FlightCardLuggagePopover";
import flights_apex_web_ancillaries_micro_conversion_aa from "utils/experiments/apex/flights_apex_web_ancillaries_micro_conversion_aa";
import flights_ace_web_flexibilty_sr_page from "utils/experiments/ace/flights_ace_web_flexibilty_sr_page";
import { isOfMetaOrigin } from "utils/marketing-url-params";
import { mcn } from "utils/mergeClassnames";
import FlightCardSegmentDesktop from "./FlightCardSegment.desktop";
import flights_web_ddot_last_available_seats_v4 from "utils/experiments/funnel/flights_web_ddot_last_available_seats_v4";
import usePointOfSale from "hooks/usePointOfSale";
import flights_apex_web_expand_bf_on_sr from "utils/experiments/apex/flights_apex_web_expand_bf_on_sr";
import { carrierUtils } from "@flights/web-api-utils-universal";
import useGlobalContext from "hooks/useGlobalContext";
import flights_apex_web_bf_on_sr_loading_message from "utils/experiments/apex/flights_apex_web_bf_on_sr_loading_message";

export type FlightCardBoundProps = {
  flight: UIFlightData;
  onClick: (flight: UIFlightData) => void;
  pricePerPerson?: boolean;
  index: number;
  bigLogo?: boolean;
  searchType?: SBSearchType;
  rebookProps?: {
    tripRebookOption: UITripRebookOption;
    initialOrder: UIOrder;
    align: "start" | "end";
  };
  /* start - flights_web_inbound_outbound_sr */
  outboundIndex?: number;
  inboundFlight?: true;
  variant?: "normal" | "condensed";
  open?: boolean;
  inboundFlightsContent?: ReactNode;
  inboundFlightsCount?: number;
  forceButtonFocusOnMount?: boolean;
  mt?: FrameProps["mt"];
  mb?: FrameProps["mb"];
  /* end - flights_web_inbound_outbound_sr */
  addFalsePositiveBrandedFare?: () => void;
  /* start - flights_alternative_fares_for_sold_out_errors_web */
  isAlternativeFareCard?: boolean;
  forceHideLastAvailableSeats?: boolean;
  /* end - flights_alternative_fares_for_sold_out_errors_web */
  brandedFaresShownByDefault?: boolean | null;
};

const FlightCardBound: FC<FlightCardBoundProps> = (props) => {
  const {
    flight,
    onClick,
    pricePerPerson,
    index,
    bigLogo,
    searchType,
    rebookProps,
    /* start - flights_web_inbound_outbound_sr */
    outboundIndex,
    inboundFlight,
    variant = "normal",
    open,
    inboundFlightsContent,
    inboundFlightsCount,
    forceButtonFocusOnMount = false,
    /* end - flights_web_inbound_outbound_sr */
    brandedFaresShownByDefault
  } = props;
  const i18n = useI18n() as I18nChildContext;
  const pointOfSale = usePointOfSale();
  const { isRTL } = useLocaleContext();
  const { isInternal } = useGlobalContext();
  const buttonRef = useRef<HTMLButtonElement>(null);
  const flightCardBoundRef = useRef<HTMLDivElement>(null);
  const isInViewport = useIsInViewport(flightCardBoundRef.current);
  const {
    searchCriteria,
    brandedFares,
    searchResults: { displayOptions }
    // eslint-disable-next-line import/no-deprecated
  } = useStore();
  const { adults, children } = searchCriteria;
  const travellersCount = adults + children.length;
  const isOutboundFlightCard = typeof open !== "undefined";
  const campaignBadges = getCampaignBadgesConfig(flight.campaignDisplay?.badges);
  const carriers = carrierUtils.getMarketingCarriers(flight.segments);
  const brandedFaresAvailable = (brandedFares.fareOffersWithDetails[flight.token]?.brandedFareOffers?.length || 0) > 0;
  const hideBrandedFaresBeforeAvailable = isFeatureRunningClientSide("FLIGHTS_ONLY_SHOW_BF_ON_SR_CTA_WHEN_AVAILABLE");
  const hasMoreThanOneInboundFlight = (flight.outboundFlightInfo?.inboundFlightsCount || 0) > 1;
  const isSwapPriceBagge = !!trackSwapPriceBaggage.variant();
  const showExpandedBf =
    displayOptions?.brandedFaresShownByDefault || !!flights_apex_web_expand_bf_on_sr.trackWithDefaultStage();

  const hasFlexibleTicketBadge = flight.ancillaries?.flexibleTicket && !flight.requestableBrandedFares;

  const isFlexibilitySrVariant =
    !!flights_ace_web_flexibilty_sr_page.variant() && !(pointOfSale === "id" || pointOfSale === "qa");

  const showFlightCardBadges =
    flight?.badges?.length || campaignBadges.length > 0 || hasFlexibleTicketBadge || flight.hasAppleCardBenefits;

  const { bestOfferHighlightStyle, bestOfferHighlightText } = getCampaignBestOfferHighlightConfig(
    flight.campaignDisplay?.bestOfferHighlight
  );
  const shouldShowBestOfferHightlight = !!(bestOfferHighlightStyle && bestOfferHighlightText);

  const cardId =
    typeof outboundIndex === "number" ? `flight-outbound-${outboundIndex}-card-${index}` : `flight-card-${index}`;

  const isCondensed = // for: flights_web_ddot_condensed_one_way_search_results and flights_web_inbound_outbound_sr
    variant === "condensed" ||
    (searchType && searchType === "ONEWAY" && !!trackExperiment("flights_web_ddot_condensed_one_way_search_results"));

  const shouldPinOfferOnSr =
    flight.highlightInfo?.highlight &&
    ((Boolean(trackExperiment("flights_web_cat_pin_flight_www")) && isOfMetaOrigin()) ||
      isFeatureRunningClientSide("FLIGHTS_WEB_PIN_OFFER_ON_SR_FROM_REDIRECT") ||
      flight.highlightInfo.reason === "CROSS_SELL_OFFER");

  const hasPrefetchedBrandedFareOffers =
    !flight.requestableBrandedFares && !!flight.brandedFareOffers && flight.brandedFareOffers?.length > 0;

  const showFareSelectorDefault =
    (flight.requestableBrandedFares || (flight.brandedFareOffers && flight.brandedFareOffers?.length > 0)) &&
    (!!brandedFaresShownByDefault || showExpandedBf);

  const [breakdownOpen, setBreakdownOpen] = useState(false);
  const [showFareSelector, toggleShowFareSelector] = useState(showFareSelectorDefault);
  const [showDivider, setShowDivider] = useState(false);

  const removeDivider = useCallback(() => setShowDivider(false), []);

  useEffect(() => {
    if (open) setShowDivider(true);
  }, [open]);

  useFetchBrandedFares(
    flight.token,
    isInViewport,
    !!flight.requestableBrandedFares,
    true,
    !!flight.brandedFareInfo?.fareName,
    carriers
  );

  useEffect(() => {
    if (forceButtonFocusOnMount && buttonRef.current) {
      buttonRef.current.focus();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const viewDetailsButtonLabel = t("flights_apex_sr_flex_cta_main");

  const toggleButtonLabel = useMemo(() => {
    if (open) {
      return inboundFlightsCount === 1
        ? t("flights_sr_rt_return_button_hide_single")
        : t("flights_sr_rt_return_button_hide");
    }

    return inboundFlightsCount === 1
      ? t("flights_sr_rt_return_button_view_single")
      : t("flights_sr_rt_return_button_view");
  }, [inboundFlightsCount, open]);

  const priceBlockAlignment = useMemo(() => {
    if (isCondensed) {
      return "flex-end";
    }

    if (trackExperiment("flights_web_swap_price_baggage_sr_desktop_2")) {
      return "center";
    }

    return undefined;
  }, [isCondensed]);

  const flightPriceComponent = rebookProps ? (
    <RebookCostBreakdown {...rebookProps} breakdownOpen={breakdownOpen} setBreakdownOpen={setBreakdownOpen} />
  ) : (
    <FlightCardPrice
      flight={flight}
      breakdownOpen={breakdownOpen}
      setBreakdownOpen={setBreakdownOpen}
      showPricePerPerson={pricePerPerson && adults + children.length > 1}
      showAvailableSeats={
        isOfMetaOrigin() && adults + children.length <= 2 && !!flights_web_ddot_last_available_seats_v4.variant()
      }
      totalPriceLabel={
        isOutboundFlightCard
          ? i18n.trans(travellersCount > 1 ? t("flights_sr_rt_price_per") : t("flights_sr_price_round_trip"))
          : undefined
      }
      priceFrom={isOutboundFlightCard && hasMoreThanOneInboundFlight}
      hidePriceBreakDown={isOutboundFlightCard}
      popoverCloseAriaLabel={i18n.trans(t("flights_a11y_price_breakdown_close_sr"))}
    />
  );

  const faresAvailable =
    flight.requestableBrandedFares ||
    (!flight.requestableBrandedFares && isFlexibilitySrVariant) ||
    (!flight.requestableBrandedFares && hasPrefetchedBrandedFareOffers);

  const renderViewDetailsBtn = isOutboundFlightCard ? (
    <Button
      text={i18n.trans(toggleButtonLabel)}
      variant="secondary"
      iconPosition="end"
      icon={
        <Icon svg={ArrowNavDownIcon} className={open ? styles.arrow : undefined} attributes={{ "aria-hidden": true }} />
      }
      onClick={() => {
        onClick(flight);
      }}
      className={isSwapPriceBagge ? styles.buttonSwapPriceBaggage : styles.button}
      attributes={{
        "aria-describedby": cardId,
        "aria-expanded": open
      }}
    />
  ) : (
    <Button
      ref={buttonRef}
      attributes={{
        "data-testid": "flight_card_bound_select_flight",
        "aria-describedby": cardId,
        "aria-label": `${i18n.trans(viewDetailsButtonLabel)} ${
          hasFlexibleTicketBadge ? i18n.trans(t("flights_apex_sr_flex_badge")) : ""
        }`
      }}
      text={i18n.trans(viewDetailsButtonLabel)}
      onClick={() => {
        flights_apex_web_expand_bf_on_sr.goals.clicks_parent_offer();
        flights_apex_web_bf_on_sr_loading_message.goals.click_on_view_details_for_main_flight();
        onClick(flight);
      }}
      variant={inboundFlight ? "primary" : "secondary"}
      className={isSwapPriceBagge ? styles.buttonSwapPriceBaggage : styles.button}
    />
  );

  const highlightFireflySR =
    isInternal &&
    flight.token.startsWith("c8e49_") &&
    new URLSearchParams(window.location.search).get("highlight_firefly") === "1";

  return (
    <div id={cardId} ref={flightCardBoundRef}>
      <Card
        fill
        className={mcn(open && styles.cardShadow, highlightFireflySR && styles.highlightFirefly)}
        variant={shouldPinOfferOnSr ? "elevated" : "neutral"}
      >
        {shouldPinOfferOnSr ? (
          <FlightCardHighlight
            style={FlightCardHighlightStyle.PRIMARY}
            text={flight.highlightInfo?.message || i18n.trans(t("flights_meta_sr_pinned_flight_label"))}
          />
        ) : shouldShowBestOfferHightlight ? (
          <FlightCardHighlight style={bestOfferHighlightStyle} text={bestOfferHighlightText} textEmphasized={true} />
        ) : null}

        <Frame p={4} direction="row" className={showDivider ? styles.divider : undefined}>
          <Frame
            attributes={{
              style: {
                width: "64%"
              }
            }}
            justifyContent="flex-start"
          >
            {showFlightCardBadges && (
              <FlightCardBadges>
                {campaignBadges.map((badge, idx) => (
                  <FlightCardBadge
                    key={`keyCampaignBadge-${idx}`}
                    alternative={false}
                    isInteractive={false}
                    {...badge}
                  />
                ))}

                {flight?.badges?.map(({ text, variant, type }, idx) => (
                  <FlightCardBadge key={text + idx} text={text} style={variant} alternative type={type} />
                ))}

                {hasFlexibleTicketBadge && (
                  <FlightCardBadge
                    style={FlightBadgeVariant.CONSTRUCTIVE}
                    alternative={true}
                    text={i18n.trans(t("flights_apex_sr_flex_badge"))}
                  />
                )}

                {flight.hasAppleCardBenefits && (
                  <FlightCardBadge
                    style={FlightBadgeVariant.CALLOUT}
                    alternative={true}
                    text={i18n.trans(t("apple_rewards_flights_sr_badge_title"))}
                  />
                )}
              </FlightCardBadges>
            )}

            <BaseFareAttributes flight={flight} />

            {flight.segments.map((segment, segmentIndex) => {
              return (
                <FlightCardSegmentDetails key={segmentIndex} idx={segmentIndex} segment={segment} bigLogo={bigLogo} />
              );
            })}

            {isCondensed && (
              <FlightCardLuggage
                segments={flight.segments}
                horizontal={true}
                brandedFareInfo={flight.brandedFareInfo}
              />
            )}
          </Frame>

          <Frame
            direction="column"
            className={mcn(
              styles.priceBlock,
              !!trackExperiment("flights_web_sr_full_luggage_data") && styles.priceBlockWider
            )}
            justifyContent={priceBlockAlignment}
          >
            {isSwapPriceBagge && (
              <div
                style={{ textAlign: isRTL ? "left" : "right", marginBottom: "var(--bui_spacing_4x)" }}
                className={mcn(!!trackExperiment("flights_web_sr_full_luggage_data") && styles.buttonArea)}
              >
                <BaseFareName flight={flight} />
                {flightPriceComponent}
              </div>
            )}
            {rebookProps?.tripRebookOption ? (
              <FlightCardLuggage segments={rebookProps.tripRebookOption.segments} />
            ) : (
              !trackExperiment("flights_web_swap_price_baggage_sr_desktop_2") &&
              !trackExperiment("flights_web_sr_full_luggage_data") &&
              !isCondensed && <FlightCardLuggage segments={flight.segments} brandedFareInfo={flight.brandedFareInfo} />
            )}

            {!trackExperiment("flights_web_swap_price_baggage_sr_desktop_2") &&
              !!trackExperiment("flights_web_sr_full_luggage_data") &&
              !isCondensed && (
                <FlightCardFullLuggageData
                  includedLuggagePerSegment={flight.includedProducts?.segments}
                  segments={flight.segments}
                  ancillaries={flight.ancillaries}
                  brandedFareInfo={flight.brandedFareInfo}
                />
              )}

            <div
              style={{ textAlign: isRTL ? "left" : "right" }}
              className={mcn(!!trackExperiment("flights_web_sr_full_luggage_data") && styles.buttonArea)}
            >
              {!isSwapPriceBagge && <BaseFareName flight={flight} /> && flightPriceComponent}

              {!!trackExperiment("flights_web_swap_price_baggage_sr_desktop_2") && (
                <FlightCardLuggagePopover flight={flight} />
              )}

              {faresAvailable &&
              (!!flights_apex_web_expand_bf_on_sr.trackWithDefaultStage() ||
                displayOptions?.brandedFaresShownByDefault) ? (
                <Stack direction="row" grow>
                  <Stack.Item alignSelf="end">
                    <Button
                      attributes={{ "aria-expanded": !!showFareSelector, "aria-controls": "flights-fare-selector" }}
                      icon={
                        <Icon
                          svg={showFareSelector ? ArrowNavUpIcon : ArrowNavDownIcon}
                          className={open ? styles.arrow : undefined}
                          attributes={{ "aria-hidden": true }}
                        />
                      }
                      variant="secondary"
                      onClick={() => {
                        toggleShowFareSelector(!showFareSelector);
                        !flight.requestableBrandedFares &&
                          isFlexibilitySrVariant &&
                          flights_ace_web_flexibilty_sr_page.goals.click_view_flexible_options();
                        if (!showFareSelector)
                          flights_apex_web_ancillaries_micro_conversion_aa.goals.show_fare_options_sr();
                        if (showFareSelector) flights_apex_web_expand_bf_on_sr.goals.collapses_fares();
                      }}
                      className={`${styles.showFaresButton} ${
                        !brandedFaresAvailable && hideBrandedFaresBeforeAvailable ? styles.hidden : ""
                      }`}
                    />
                  </Stack.Item>
                  <Stack.Item grow>{renderViewDetailsBtn}</Stack.Item>
                </Stack>
              ) : (
                <>
                  {renderViewDetailsBtn}
                  {faresAvailable ? (
                    <Button
                      attributes={{ "aria-expanded": !!showFareSelector, "aria-controls": "flights-fare-selector" }}
                      text={
                        showFareSelector
                          ? !flight.requestableBrandedFares && isFlexibilitySrVariant
                            ? i18n.trans(t("flights_ace_sr_ticket_type_hide"))
                            : i18n.trans(t("flights_apex_sr_flex_cta_hide"))
                          : !flight.requestableBrandedFares && isFlexibilitySrVariant
                          ? i18n.trans(t("flights_ace_sr_ticket_type_explore"))
                          : i18n.trans(t("flights_apex_sr_flex_cta_explore"))
                      }
                      onClick={() => {
                        toggleShowFareSelector(!showFareSelector);
                        !flight.requestableBrandedFares &&
                          isFlexibilitySrVariant &&
                          flights_ace_web_flexibilty_sr_page.goals.click_view_flexible_options();
                        if (!showFareSelector)
                          flights_apex_web_ancillaries_micro_conversion_aa.goals.show_fare_options_sr();
                        else flights_apex_web_bf_on_sr_loading_message.goals.close_expanded_branded_fares();
                      }}
                      variant="tertiary"
                      iconPosition="end"
                      wide={true}
                      icon={
                        <Icon
                          svg={showFareSelector ? ArrowNavUpIcon : ArrowNavDownIcon}
                          className={open ? styles.arrow : undefined}
                          attributes={{ "aria-hidden": true }}
                        />
                      }
                      className={`${styles.showFaresButton} ${
                        !brandedFaresAvailable && hideBrandedFaresBeforeAvailable ? styles.hidden : ""
                      }`}
                    />
                  ) : null}
                </>
              )}
            </div>
          </Frame>
        </Frame>

        {showFareSelector && (
          <FareSelectorDesktop
            flight={flight}
            brandedFaresShownByDefault={displayOptions?.brandedFaresShownByDefault ?? undefined}
          />
        )}

        {isOutboundFlightCard && (
          <AnimatePresence onExitComplete={removeDivider} initial={false}>
            {open && (
              <motion.div
                initial={{ height: 0 }}
                animate={{ height: "auto" }}
                exit={{ height: 0 }}
                transition={{ duration: 0.2 }}
              >
                <Frame mb={4} pt={4} pr={4}>
                  {inboundFlightsContent}
                </Frame>
              </motion.div>
            )}
          </AnimatePresence>
        )}
      </Card>
    </div>
  );
};

const FlightCardSegmentDetails: FC<{ segment: UIFlightSegment; idx: number; bigLogo?: boolean }> = ({
  segment,
  idx,
  bigLogo
}) => {
  const { selectFlightCarriers: carriers } = useAirlineLogos(segment);
  const segmentCarriersClass = bigLogo ? styles.segmentCarriersV2 : styles.segmentCarriers;
  const segmentDetailsClass = bigLogo ? styles.segmentDetailsV2 : styles.segmentDetails;

  return (
    <>
      <Frame direction="row" className={styles.segment} justifyContent="space-between" alignItems="center">
        <Frame className={segmentCarriersClass} justifyContent="space-between" alignItems="flex-start">
          {bigLogo ? (
            <FlightCardCarriersV2 segment={segment} idx={idx} />
          ) : (
            <FlightCardCarriers segment={segment} idx={idx} />
          )}
        </Frame>
        <Frame className={segmentDetailsClass}>
          <FlightCardSegmentDesktop segment={segment} idx={idx} />
        </Frame>
      </Frame>

      <Frame mt={2}>
        <CarrierNames carriers={carriers} idx={idx} />
      </Frame>
    </>
  );
};

const FlightCardDesktop = React.memo(FlightCardBound);
export default FlightCardDesktop;
