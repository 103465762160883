import { updateStateBlobAndVariants } from "./etV2.js";
// Updating ET tracking library's state after receiving new clientside payload
export const checkForNewClientsidePayload = (newClientsidePayload) => {
    if (newClientsidePayload) {
        if (newClientsidePayload !== window?.__GLOBAL_CONTEXT__?.clientsidePayload) {
            try {
                updateStateBlobAndVariants(newClientsidePayload);
                window.__GLOBAL_CONTEXT__.clientsidePayload = newClientsidePayload;
            }
            catch (error) {
                throw new Error("EtV2 threw an error while updating stateBlob and variants");
            }
        }
        else {
            throw new Error("Etv2 info. Attempted to update stateBlob and variants with the same payload");
        }
    }
};
export const updateClientSideContext = (soylentExperiments) => {
    if (soylentExperiments) {
        try {
            window.__GLOBAL_CONTEXT__.experiments = {
                ...window.__GLOBAL_CONTEXT__.experiments,
                ...JSON.parse(soylentExperiments)
            };
        }
        catch {
            throw new Error("Error parsing soylent experiments payload");
        }
    }
};
