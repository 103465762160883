import { Spinner, Stack } from "@bookingcom/bui-react";
import React from "react";
import { UIFlightData } from "@flights/types";
import FareCard from "../../FlightCard/components/FareCard";
import { SkeletonFlightCard } from "../../Skeleton/SkeletonFlightCard";
import useMeasureBrandedFaresWaitTime from "../hooks/useMeasureBrandedFaresWaitTime";
import { convertFlightToFare } from "../utils";
import { useI18n } from "@bookingcom/lingojs-react";
import useBrandedFares from "../hooks/useBrandedFares";
import Frame from "components/elements/Frame";
import useTrackFirstFarePriceMismatch from "components/elements/FareSelector/hooks/useTrackFirstFarePriceMismatch";
import { t } from "@bookingcom/lingojs-core";
import flights_apex_web_bf_on_sr_loading_message from "utils/experiments/apex/flights_apex_web_bf_on_sr_loading_message";
import useTrackBaggageToServerStages from "components/elements/SearchResults/hooks/useTrackBaggageToServerStages";

type Props = {
  flight: UIFlightData;
};

const FareSelector = ({ flight }: Props) => {
  const i18n = useI18n();
  const { fetchStatus, brandedFareOffers } = useBrandedFares(flight.token);
  const hasBrandedFareOffers = !!brandedFareOffers && brandedFareOffers.length > 0;
  const [hasSeenLoading, setHasSeenLoading] = React.useState(false);

  useMeasureBrandedFaresWaitTime(fetchStatus, hasBrandedFareOffers);
  useTrackFirstFarePriceMismatch(flight, brandedFareOffers);
  useTrackBaggageToServerStages();

  if (fetchStatus === "loading" && !hasBrandedFareOffers && !hasSeenLoading) {
    setHasSeenLoading(true);
    flights_apex_web_bf_on_sr_loading_message.stages.mdot();
  }

  if (fetchStatus === "success" && hasSeenLoading) {
    if (!hasBrandedFareOffers) flights_apex_web_bf_on_sr_loading_message.stages.false_positive();
    else flights_apex_web_bf_on_sr_loading_message.stages.not_false_positive();
    flights_apex_web_bf_on_sr_loading_message.goals.waited_until_loading_is_done();
  }

  return (
    <Frame ml={4} mr={4} mb={4}>
      <Stack gap={1}>
        {hasBrandedFareOffers ? (
          brandedFareOffers.map((brandedFareOffer, i) => (
            <FareCard
              key={brandedFareOffer.token}
              index={i}
              fareOffer={brandedFareOffer}
              baseOffer={flight}
              fetchStatus={fetchStatus}
            />
          ))
        ) : (
          <>
            {fetchStatus === "loading" && !!flights_apex_web_bf_on_sr_loading_message.trackWithDefaultStage() && (
              <Stack direction="row" wrap="nowrap">
                <Frame pt={0.5}>
                  <Spinner size="small" color="action" />
                </Frame>
                <Stack.Item shrink={true}> {i18n.trans(t("flights_apex_sr_branded_fares_loading"))}</Stack.Item>
              </Stack>
            )}
            <FareCard fareOffer={convertFlightToFare(flight, i18n)} baseOffer={flight} fetchStatus={fetchStatus} />
            {fetchStatus === "loading" && <SkeletonFlightCard />}
          </>
        )}
      </Stack>
    </Frame>
  );
};

export default FareSelector;
