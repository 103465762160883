import { useEffect } from "react";
import { actions as brandedFaresActions } from "../../../../store/brandedFareOffers/actions";
import { UIBrandedFare } from "@flights/types";
import { clientFetch } from "@flights/client-fetch";
import useClientMetrics from "hooks/useClientMetrics";
import { appendFeatureAndExperimentParams, appendLanguageOptionParam } from "utils/fetchParams";
import { useDispatch, useSelector } from "react-redux";
import { getFareOffersWithDetails } from "store/brandedFareOffers/selectors";

export default function useFetchBrandedFares(
  offerToken: string,
  isInViewport: boolean,
  isRequestable: boolean,
  isOnSearch?: boolean,
  baseOfferFareNameAvailable?: boolean,
  carriers?: string[]
) {
  const fareOffers = useSelector(getFareOffersWithDetails);
  const dispatch = useDispatch();
  const { trackClientMetric } = useClientMetrics();
  const hasFetched = fareOffers[offerToken];
  const shouldFetch = isInViewport && isRequestable && !hasFetched && !!offerToken;

  const fetchBrandedFares = () => {
    const brandedFareParams = new URLSearchParams();

    carriers?.forEach((carrier) => brandedFareParams.append("airlines[]", carrier));

    const url = appendFeatureAndExperimentParams(
      appendLanguageOptionParam(`/api/brandedFares/${offerToken}?${brandedFareParams.toString()}`)
    );

    clientFetch(url)
      .then((res: { brandedFares: UIBrandedFare[] }) => {
        if (isOnSearch && res?.brandedFares?.length > 1) {
          trackClientMetric("branded_fares_on_search_results_available");

          if (baseOfferFareNameAvailable) {
            trackClientMetric("branded_fares_on_search_results_available_and_fare_name_available");
          }
        }

        dispatch(brandedFaresActions.fetchSuccess(res.brandedFares, offerToken));
      })
      .catch((err) => {
        if (isOnSearch) {
          trackClientMetric("branded_fares_on_search_results_failed");
        }

        dispatch(brandedFaresActions.fetchError(err, offerToken));
      })
      .finally(() => {
        if (isOnSearch) {
          trackClientMetric("branded_fares_on_search_results_requestable");

          if (baseOfferFareNameAvailable) {
            trackClientMetric("branded_fares_on_search_results_requestable_and_fare_name_available");
          }
        }
      });
  };

  useEffect(() => {
    if (!shouldFetch) return;
    dispatch(brandedFaresActions.fetch(offerToken));
    fetchBrandedFares();
  }, [offerToken, shouldFetch]); //eslint-disable-line react-hooks/exhaustive-deps
}
