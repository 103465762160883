import { flightsSessionStore } from "./browser-storage.js";
const MARKETING_TRACKING_PARAMS = [
    "ext-src",
    "adplat",
    "aid",
    "label",
    "ext-origin",
    "ext-fwd",
    "ext-tr",
    "salesCountry"
];
export const getMarketingTrackingVariable = (trackingParam) => {
    const storedTrackingParam = flightsSessionStore && flightsSessionStore.get(trackingParam);
    if (storedTrackingParam && trackingParam == "aid") {
        return Number(storedTrackingParam);
    }
    else {
        return storedTrackingParam;
    }
};
