import useProfiler from "hooks/useProfiler";
import { createTrackingUtils } from "..";
import { useSelector } from "react-redux";
import { getBrandedFareOffers, getSubsidizedFares } from "store/flightDetails/selectors";
import usePointOfSale from "hooks/usePointOfSale";
import { isSirfAvailable } from "store/selectors/sirf";
import { useCallback } from "react";
import { flightsSessionStore } from "@flights/web-api-utils-universal";
import useClientMetrics from "hooks/useClientMetrics";

const utils = createTrackingUtils({
  name: "flights_web_persist_checkout_selections",
  stages: {
    check_available_checkout_steps: 1,
    checkout_start: 2, // Main
    desktop: 3,
    mobile: 4,
    offer_is_branded_fare: 5,
    offer_is_not_branded_fare: 6,
    offer_is_sirf: 7,
    pos_gb: 8,
    pos_us: 9
  },
  defaultStage: 1,
  goals: {},
  goalsWithValue: []
});

const { stages } = utils;

export const useTrackPersistAncillariesOnCheckout = () => {
  const { isDesktop, isMobile } = useProfiler();
  const brandedFareOffers = useSelector(getBrandedFareOffers);
  const subsidizedFares = useSelector(getSubsidizedFares);
  const POS = usePointOfSale();

  const isBrandedFare = brandedFareOffers && !!brandedFareOffers.length;
  const isSirf = subsidizedFares && isSirfAvailable(subsidizedFares);

  stages.checkout_start(); // Main

  if (isDesktop()) stages.desktop();
  if (isMobile()) stages.mobile();
  if (isBrandedFare) stages.offer_is_branded_fare();
  if (!isBrandedFare) stages.offer_is_not_branded_fare();
  if (isSirf) stages.offer_is_sirf();
  if (POS === "gb") stages.pos_gb();
  if (POS === "us") stages.pos_us();
};

export const useTrackLoadSpeed = () => {
  const { trackClientMetric } = useClientMetrics();
  const key = "MEASURE_CHECKOUT_START_TIME";

  const onEnterCheckoutStart = useCallback(() => {
    flightsSessionStore.set(key, Date.now());
  }, []);

  const onFullyLoadFirstPageAfterCheckoutStart = useCallback(
    (isVariant: boolean) => {
      const startTime = flightsSessionStore.get(key);

      if (!startTime) return;

      const duration = Date.now() - startTime;

      isVariant
        ? trackClientMetric("flight_persist_ancillaries_checkout_variant", duration)
        : trackClientMetric("flight_persist_ancillaries_checkout_base", duration);

      flightsSessionStore.unset(key);
    },
    [trackClientMetric]
  );

  return {
    onEnterCheckoutStart,
    onFullyLoadFirstPageAfterCheckoutStart
  };
};

export default utils;
