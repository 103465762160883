import { getInitialState, State } from "./state";
import { Actions, ActionTypes } from "./actions";

const initialState = getInitialState();

const reducer = (state: State = initialState, action: Actions): State => {
  switch (action.type) {
    case ActionTypes.fetch:
      return {
        ...state,
        searchAiSummary: {},
        fetchStatus: "loading",
        error: undefined
      };
    case ActionTypes.fetchSuccess:
      return {
        ...state,
        searchAiSummary: action.payload.data,
        fetchStatus: "success",
        error: undefined
      };

    case ActionTypes.fetchError:
      return {
        ...state,
        fetchStatus: "failed",
        error: action.payload.error,
        searchAiSummary: undefined
      };
    default:
      return {
        ...state
      };
  }
};

export { reducer, getInitialState };
