import { useEffect } from "react";
import { useSelector } from "react-redux";
import flights_apex_web_move_baggage_features_to_server from "utils/experiments/apex/flights_apex_web_move_baggage_features_to_server";
import { areSellableFeaturesRequiredByAirline } from "@flights/ancillaries";
import { getSearchResultsFlights } from "store/searchResults/selectors";

export default function useTrackBaggageToServerStages() {
  const flights = useSelector(getSearchResultsFlights);

  useEffect(() => {
    if (flights.length === 0) return;
    if (flights.every((flight) => !flight.requestableBrandedFares)) return;

    flights_apex_web_move_baggage_features_to_server.stages.sr_with_bf();

    if (flights.some((flight) => areSellableFeaturesRequiredByAirline(flight.segments))) {
      flights_apex_web_move_baggage_features_to_server.stages.sellable_required();
    }
  }, [flights]);
}
