/* eslint-disable no-restricted-syntax -- this line was auto generated, hence fix the issue timely */
import React from "react";
import { Text, Title, useTheme } from "@bookingcom/bui-react";
import { t } from "@bookingcom/lingojs-core";
import { useI18n } from "@bookingcom/lingojs-react";

import Frame from "components/elements/Frame";

const LiveChatEnd: React.FC = () => {
  const i18n = useI18n();
  const theme = useTheme();
  const styles = React.useMemo(
    () => ({
      // hard coded background color according to design document
      backgroundColor: "#003580",
      color: theme.colors.color_white,
      height: "100vh"
    }),
    [theme]
  );

  return (
    <div style={styles}>
      <Frame p={8} alignItems="center">
        <Title color="inherit" variant="headline_2" title={i18n.trans(t("flights_hc_chat_ended_header"))} />
        <Text variant="body_1">{i18n.trans(t("flights_hc_chat_ended_description"))}</Text>
      </Frame>
    </div>
  );
};

export default LiveChatEnd;
